import React, {useEffect} from "react";
import {BusinessSettingsPageWrapper, Key, KeyValPair, List, Toolbar} from "./styles";
import {Button} from "../../components/common/button";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import {validationSchema} from "../toggles/validation";
import {Title} from "../toggles/styles";
import {Input} from "../../components/common";
import {Switch} from "@mui/material";
import {adminActions, selectCustomerInfo} from "../../store/admin";
import {selectAdminLoading} from "../../store/admin/selectors";
import {Loader} from "../../components/loader";

export const BusinessSettingsPage: React.FC = () => {
    const customer = useSelector(selectCustomerInfo);
    const loading = useSelector(selectAdminLoading);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    useEffect(() => {
        dispatch(adminActions.getCustomerInfo());
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema,
        initialValues: {
            name: customer?.name || '',
            quota: `${customer?.monthly_quota || ''}`,
            autoRenew: customer?.auto_renew || false
        },
        onSubmit: values => {
            dispatch(adminActions.updateCustomerInfo({
                name: values.name,
                monthly_quota: Number(values.quota),
                auto_renew: values.autoRenew
            }));
        }
    })

    return (<BusinessSettingsPageWrapper>
        <Toolbar>
            <Button label={t('SAVE')} disabled={!formik.dirty || !formik.isValid} onClick={formik.submitForm}/>
            <Title>{t('BUSINESS_SETTINGS_PAGE')}</Title>
        </Toolbar>
        <List dir="rtl">
            <KeyValPair>
                <Key>{t('BUSINESS_NAME')}</Key>
                <Input
                    border
                    placeholder={t('BUSINESS_NAME')}
                    value={formik.values.name}
                    onChange={e => formik.setFieldValue('name', e.target.value)}
                    style={{width: '300px', minWidth: '300px', textAlign: 'right'}}
                    error={formik.values.name === ''}
                />
            </KeyValPair>
            <KeyValPair>
                <Key>{t('MONTHLY_QUOTA')}</Key>
                <Input
                    border
                    placeholder={t('MONTHLY_QUOTA')}
                    value={formik.values.quota}
                    onChange={e => formik.setFieldValue('quota', e.target.value)}
                    style={{width: '300px', minWidth: '300px', textAlign: 'right'}}
                    error={formik.values.quota === ''}
                />
            </KeyValPair>
            <KeyValPair>
                <Key>{t('AUTO_RENEW')}</Key>
                <Switch
                    checked={formik.values.autoRenew}
                    onChange={e => formik.setFieldValue('autoRenew', !formik.values.autoRenew)}/>
            </KeyValPair>
        </List>
        <Loader show={loading} />
    </BusinessSettingsPageWrapper>)
}

export default BusinessSettingsPage;
