import styled from "styled-components";
import colors from "../../styles/colors";

export const LoginPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${colors.theme.galilDark};
  box-sizing: border-box;
`;

export const BackgroundImage = styled.img`
  width: 100%;
  bottom: 0;
  position: absolute;
`;

export const GalilLogo = styled.img`
  width: 18%;
  left: 70%;
  top: 20%;
  position: absolute;
`;

export const FormWrapper = styled.div`
  width: 320px;
  box-sizing: border-box;
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

export const ErrorMessage = styled.div`
  width: 100%;
  box-sizing: border-box;
  color: #f44f0b;
  font-size: 1rem;
  font-weight: normal;
  text-align: right;
`;

export const Title = styled.div`
  font-size: 0.9rem;
  color: ${colors.theme.galilWhite};
  text-align: right;
  padding-top: 8px;
`;

export const LoginForm = styled.form`
  position: absolute;
  box-sizing: border-box;
  height: 100%;
  width: 55%;
  left: 0;
  top: 0;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.12);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.9px);
  -webkit-backdrop-filter: blur(10.9px);
  border-right: 1px solid rgba(255, 255, 255, 0.27);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  button {
    margin-top: 8px;
  }
`;

export const LinkButton = styled.div`
  box-sizing: border-box;
  text-decoration: underline;
  font-size: 1rem;
  color: ${colors.theme.galilGreen};
  font-weight: normal;
  text-align: center;
  transition: color 0.3s linear;
  cursor: pointer;
  &:hover {
    color: ${colors.theme.galilGreen80};
  }
`;
