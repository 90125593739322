import React, {useEffect, useState} from "react";
import {DashboardPageWrapper, Toolbar, Title} from "./styles";
import DashboardFilters from "../../components/filters/DashboardFilters";
import dayjs, {Dayjs} from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {selectAdminLoading, selectStatistics} from "../../store/admin/selectors";
import StatisticsGraphs from "../../components/statistics/StatisticsGraphs";
import {adminActions} from "../../store/admin";
import {useTranslation} from "react-i18next";

const DashboardPage: React.FC = () => {
    const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().subtract(1, 'day'));
    const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
    const statistics = useSelector(selectStatistics);
    const isLoading = useSelector(selectAdminLoading);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if(startDate && endDate) {
            dispatch(adminActions.getStatistics({
                startDate: startDate.toDate().getTime(),
                endDate: endDate.toDate().getTime()
            }))
        }
    }, [startDate, endDate]);

    return <DashboardPageWrapper id="dashboard-page">
        <Toolbar>
            <DashboardFilters
                startDate={startDate}
                endDate={endDate}
                onStartDateChanged={value => {
                    setStartDate(value);
                    if(endDate && value && endDate.isBefore(value)) {
                        setEndDate(value.add(1, 'day'));
                    }
                }}
                onEndDateChanged={value => {
                    setEndDate(value)
                    if(startDate && value && startDate.isAfter(value)) {
                        setStartDate(value.subtract(1, 'day'));
                    }
                }}
            />
            <Title>{t('SYSTEM_STATUS')}</Title>
        </Toolbar>
        <StatisticsGraphs startDate={startDate} endDate={endDate} stats={statistics} isLoading={isLoading}/>
    </DashboardPageWrapper>
}

export default DashboardPage;
