import React, {useEffect, useState} from "react";
import FileUpload from "../../components/upload/FileUpload";
import {
    UploadPageSections,
    UploadPageWrapper,
    Navigation,
    NavigationPart,
    NavigationWrapper,
    Next,
    Previous
} from "./styles";
import api from "../../network/api";
import PdfViewer from "../../components/pdf/PdfViewer";
import PdfData from "../../components/data/PdfData";
import {Button} from "../../components/common/button";
import {useDispatch} from "react-redux";
import {userActions} from "../../store/user";
import {useTranslation} from "react-i18next";
import {alertsActions} from "../../store/alerts";

const UploadPage: React.FC = () => {
    const [data, setData] = useState<{file: File; data: any | undefined; isLoading: boolean;}[]>([]);
    const [selectedFile, setSelectedFile] = useState<number | null>(null);
    const [fileLoadError, setFileLoadError] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<{[key: number]: boolean}>({});
    const [uploadProgress, setUploadProgress] = useState<number | undefined>(undefined);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const onStartUpload = async () => {
        if(selectedFile === null) return;
        const copy = [...data];
        copy[selectedFile].isLoading = true;
        setData(copy);
        const result = await api.processFile(
            data[selectedFile].file,
                e => setUploadProgress(e.total ? parseInt(`${100 * e.loaded / e.total}`) : undefined));
        if(result != null) {
            const copy = [...data];
            copy[selectedFile].data = result;
            copy[selectedFile].isLoading = false;
            setData(copy);
            dispatch(userActions.loadData());
        } else {
            const copy = [...data];
            copy[selectedFile].isLoading = false;
            setData(copy);
            dispatch(alertsActions.enqueue({sevirity: 'error', text: t('FILE_UPLOAD_ERROR')}))
        }
    }

    useEffect(() => {
        setFileLoadError(false);
    }, [selectedFile])

    const onError = () => {
        setFileLoadError(true);
    }

    const onStartClicked = (files: File[]) => {
        const index = data?.length || 0;
        setData([...data, ...files.map(file => ({
            file,
            data: undefined,
            isLoading: false
        }))]);
        setSelectedFile(index);
    }

    const onDownload = () => {
        if(selectedFile !== null && data[selectedFile].data) {
            const dataStr = JSON.stringify((data[selectedFile].data));
            const a = document.createElement("a")
            a.href = URL.createObjectURL(
                new Blob([dataStr], {type:"application/json"})
            )
            a.download = `${data[selectedFile].file.name.split('.')[0]}.json`;
            a.click();
        }
    }

    return (<UploadPageWrapper key="upload-page-wrapper">
        {data && (selectedFile !== null) ? <Navigation>
            <NavigationPart>
                <Button
                    narrow
                    label={t('ADD_NEW_FILES')}
                    onClick={() => setSelectedFile(null)} />
                <NavigationWrapper>
                    <Next disabled={selectedFile === data.length - 1} onClick={
                        selectedFile === data.length - 1 ? undefined : () => setSelectedFile(selectedFile + 1)}>{t('NEXT')}</Next>
                    <span>{t('FILE_OF_FILES', { index: selectedFile + 1, count: data.length })}</span>
                    <Previous disabled={selectedFile === 0} onClick={
                        selectedFile === 0 ? undefined : () => setSelectedFile(selectedFile - 1)}>{t('PREVIOUS')}</Previous>
                </NavigationWrapper>
                <div />
            </NavigationPart>
            <Button
                narrow
                disabled={selectedFile !== null && data[selectedFile].data === undefined}
                label={t('DOWNLOAD_RESULTS')}
                onClick={onDownload} />
        </Navigation> : ''}
        { selectedFile === null && <FileUpload
            data={data}
            onStartClicked={onStartClicked}
            uploadProgress={uploadProgress}
            onCancel={() => setSelectedFile(0)}
        /> }
        { selectedFile !== null &&
            <UploadPageSections>
                <PdfViewer
                    key={`pdf-${selectedFile}`}
                    files={data?.map(datum => datum.file) || []}
                    selectedFile={selectedFile}
                    onError={onError}
                />
                <PdfData
                    key={`data-${selectedFile}`}
                    fileName={data[selectedFile]?.file.name}
                    data={data[selectedFile]?.data}
                    loading={data[selectedFile]?.isLoading}
                    onStartUpload={onStartUpload}
                    fileLoadError={fileLoadError}
                    editMode={editMode[selectedFile]}
                    toggleEditMode={() => {
                        const copy = {...editMode};
                        copy[selectedFile] = !copy[selectedFile]
                        setEditMode(copy);
                    }}
                    onChange={(key: string, value: string) => {
                        const dataCopy = [...data];
                        dataCopy[selectedFile].data.result[key] = value;
                        setData(dataCopy);
                    }}
                    onItemChange={(index: number, key: string, value: string) => {
                        const dataCopy = [...data];
                        dataCopy[selectedFile].data.result.items[index][key] = value;
                        setData(dataCopy);
                    }}
                />
            </UploadPageSections>
        }
    </UploadPageWrapper>);
}

export default UploadPage;
