import styled from "styled-components";
import colors from "../../../styles/colors";

export const StyledButton = styled.button<{disabled?: boolean; narrow?: boolean;}>`
  min-width: 100px;
  height: ${props => props.narrow ? 28 : 36}px;
  background-color: ${colors.theme.galilGreen};
  border-radius: 6px;
  box-sizing: border-box;
  padding: ${props => props.narrow ? '2px 6px' : '4px 12px'};
  border: none;
  cursor: pointer;
  color: white;
  font-weight: 600;
  opacity: ${props => props.disabled ? 0.3 : 1};
  ${props => props.disabled ? 'pointer-events: none;' : ''};
  &:hover {
    background-color: ${colors.theme.galilGreen80};
  }
`;
