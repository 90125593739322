import axios, {AxiosRequestConfig, AxiosProgressEvent, AxiosResponse} from 'axios';
import {StorageKey} from "../store/storage";
import {Quota} from "../types/Quota";
import {UserData} from "../types/UserData";
import {UserInfo} from "../types/UserInfo";
import {Statistics} from "../types/Statistics";
import {BaseFeatureToggle, FeatureToggle} from "../types/FeatureToggle";
import {Metadata} from "../types/Metadata";
import {Customer} from "../types/Customer";

const baseURL =
    window.location.origin.includes('localhost')
        ?
        'http://localhost:5001/'// process.env.REACT_APP_API_BASE_URL || 'https://billbotai.galilsoftware.com/'
        :
        `${window.location.origin}/`;

const commonHeaders = {
    'Content-Type': 'application/JSON',
};

const instance = axios.create({baseURL, headers: commonHeaders});

let onLoggedOut: () => void = () => {};

const setOnLoggedOut = (cb: () => void) => {
    onLoggedOut = cb;
}

const post = async <T>(
    path: string,
    body: any,
    headers: any = {},
    onUploadProgress: ((progressEvent: AxiosProgressEvent) => void) | undefined = undefined
): Promise<AxiosResponse<T>> => {
    const token = localStorage.getItem(StorageKey.TOKEN);
    const configs: AxiosRequestConfig = {
        baseURL,
        headers: token
            ?
            {...commonHeaders, ...headers, Authorization: `Bearer ${token}`}
            :
            {...commonHeaders, ...headers},
        onUploadProgress,
    }
    try {
        return await instance.post<T>(`/${path}`, body, configs);
    } catch(e: any) {
        if(e.response?.status === 401 || e.response?.status === 403) {
            onLoggedOut();
        }
        throw(e);
    }
}

const _delete = async <T>(
    path: string,
    headers: any = {},
): Promise<AxiosResponse<T>> => {
    const token = localStorage.getItem(StorageKey.TOKEN);
    const configs: AxiosRequestConfig = {
        baseURL,
        headers: token
            ?
            {...commonHeaders, ...headers, Authorization: `Bearer ${token}`}
            :
            {...commonHeaders, ...headers},
    }
    try {
        return await instance.delete<T>(`/${path}`, configs);
    } catch(e: any) {
        if(e.response?.status === 401 || e.response?.status === 403) {
            onLoggedOut();
        }
        throw(e);
    }
}

const put = async <T>(
    path: string,
    body: any,
    headers: any = {},
    onUploadProgress: ((progressEvent: AxiosProgressEvent) => void) | undefined = undefined
): Promise<AxiosResponse<T>> => {
    const token = localStorage.getItem(StorageKey.TOKEN);
    const configs: AxiosRequestConfig = {
        baseURL,
        headers: token
            ?
            {...commonHeaders, ...headers, Authorization: `Bearer ${token}`}
            :
            {...commonHeaders, ...headers},
        onUploadProgress,
    }
    try {
        return await instance.put<T>(`/${path}`, body, configs);
    } catch(e: any) {
        if(e.response?.status === 401 || e.response?.status === 403) {
            onLoggedOut();
        }
        throw(e);
    }
}

const get = async <T>(
    path: string,
    headers: any = {}
): Promise<AxiosResponse<T>> => {
    const token = localStorage.getItem(StorageKey.TOKEN);
    const configs: AxiosRequestConfig = {
        baseURL,
        headers: token
            ?
            {...commonHeaders, ...headers, Authorization: `Bearer ${token}`}
            :
            {...commonHeaders, ...headers},
    }
    try {
        return await instance.get<T>(`/${path}`, configs);
    } catch(e: any) {
        if(e.response?.status === 401 || e.response?.status === 403) {
            onLoggedOut();
        }
        throw(e);
    }
}

const login = async (email: string, password: string) => {
    try {
        const res = await post<{ access_token: string; }>('login', {email, password});
        if (res.status === 200) {
            return res.data.access_token
        }
        return null;
    } catch(e) {
        return null;
    }
}

const getMetadata = async (): Promise<Metadata | null> => {
    try {
        const res = await get<{ metadata: Metadata }>('metadata');
        if (res.status === 200) {
            return res.data.metadata;
        }
        return null;
    } catch(e) {
        return null;
    }
}

const signup = async (email: string, phone: string, name: string) => {
    try {
        const res = await post<{}>('sign_up', {email, phone, name});
        if (res.status === 200) {
            return true;
        }
        return false;
    } catch(e) {
        return false;
    }
}

const getQuota = async () => {
    try {
        const res = await get<Quota>('quota');
        if (res.status === 200) {
            return res.data;
        }
        return null;
    } catch(e) {
        return null;
    }
}

const getUserData = async () => {
    try {
        const res = await get<UserData>('user_data');
        if (res.status === 200) {
            return res.data;
        }
        return null;
    } catch(e) {
        return null;
    }
}

const getUsersList = async () => {
    try {
        const res = await get<UserInfo[]>('users');
        if (res.status === 200) {
            return res.data;
        }
        return null;
    } catch(e) {
        return null;
    }
}

const getStatistics = async (start_date: number, end_date: number) => {
    try {
        const res = await post<Statistics>('dashboard', { start_date, end_date });
        if (res.status === 200) {
            return res.data;
        }
        return null;
    } catch(e) {
        return null;
    }
}

const getTokens = async () => {
    try {
        const res = await get<FeatureToggle[]>('tokens');
        if (res.status === 200) {
            return res.data;
        }
        return null;
    } catch(e) {
        return null;
    }
}

const addToken = async (name: string, folder: string) => {
    try {
        const res = await post<{}>(`token`, {token_name: name, folder_name: folder} );
        return res.status === 200
    } catch(e) {
        return false;
    }
}

const deleteToken = async (tokenId: string) => {
    try {
        const res = await _delete<{}>(`token/${tokenId}`);
        console.log('res', res, res.status === 200)
        return res.status === 200
    } catch(e) {
        return false;
    }
}

const getFeatureToggles = async () => {
    try {
        const res = await get<FeatureToggle[]>('feature_toggles');
        if (res.status === 200) {
            return res.data;
        }
        return null;
    } catch(e) {
        return null;
    }
}

const getCustomerInfo = async () => {
    try {
        const res = await get<Customer>('customer');
        if (res.status === 200) {
            return res.data;
        }
        return null;
    } catch(e) {
        return null;
    }
}

const updateCustomerInfo = async (info: Partial<Customer>) => {
    try {
        const res = await post<{}>('customer', info );
        return res.status === 200
    } catch(e) {
        return false;
    }
}

const updateFeatureToggle = async (toggle: FeatureToggle) => {
    try {
        const res = await put<{}>(`feature_toggle/${toggle._id}`, toggle );
        return res.status === 200
    } catch(e) {
        return false;
    }
}

const addFeatureToggle = async (toggle: BaseFeatureToggle) => {
    try {
        const res = await post<{}>(`feature_toggle`, toggle );
        return res.status === 200
    } catch(e) {
        return false;
    }
}

const increaseUserQuota = async (user_id: string, quota: number) => {
    try {
        const res = await put<{}>('extra_quota',{ user_id, quota } );
        return res.status === 200
    } catch(e) {
        return false;
    }
}

const updateUserStatus = async (user_id: string, disabled: boolean) => {
    try {
        const res = await put<{}>('toggle_user',{ user_id, action: disabled ? 'disable' : 'enable' } );
        return res.status === 200
    } catch(e) {
        return false;
    }
}

const resetUserPassword = async (user_id: string) => {
    try {
        const res = await post<{}>('reset_password',{ user_id } );
        return res.status === 200
    } catch(e) {
        return false;
    }
}

const processFile = async (file: File, onUploadProgress: (progressEvent: AxiosProgressEvent) => void) => {
    try {
        const formData = new FormData();
        formData.append("max_num_pages", '20');
        formData.append("file", file);
        const res = await post<{ token: string; }>('upload', formData, {}, onUploadProgress);
        if (res.status === 200) {
            return res.data;
        }
        return null;
    } catch(e: any) {
        return null;
    }
}

const api = {
    getMetadata,
    login,
    signup,
    getQuota,
    getUserData,
    processFile,
    setOnLoggedOut,
    getUsersList,
    increaseUserQuota,
    updateUserStatus,
    resetUserPassword,
    getStatistics,
    getFeatureToggles,
    updateFeatureToggle,
    addFeatureToggle,
    getTokens,
    addToken,
    deleteToken,
    getCustomerInfo,
    updateCustomerInfo
}

export default  api;
