import React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {Button} from "../common/button";
import {useTranslation} from "react-i18next";

interface Props {
    open: boolean;
    title?: string;
    text?: string;
    onClose: () => void;
    onApprove: () => void;
}

const ValidationDialog: React.FC<Props> = (props: Props) =>{
    const { title, text, open, onClose, onApprove } = props;
    const {t} = useTranslation();

    return (<Dialog open={open} dir="rtl">
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <span>{text}</span>
        </DialogContent>
        <DialogActions sx={{gap: '4px'}}>
            <Button narrow label={t('CANCEL')} onClick={onClose} />
            <Button narrow label={t('APPROVE')} onClick={onApprove} />
        </DialogActions>
    </Dialog>);
}

export default ValidationDialog;
