export const sections: {title: string; key?: string; items?: {key: string; label: string; type?: string;}[]}[] = [
    {
        title: 'GENERAL_INFO',
        key: undefined,
        items: [
            { key: 'page_of_total', label: 'PAGE_OF_TOTAL' },
            { key: 'number_of_pages', label: 'NUMBER_OF_PAGES' },
            { key: 'invoice_number', label: 'INVOICE_NUMBER' },
            { key: 'invoice_date', label: 'INVOICE_DATE' },
            { key: 'tax_invoice_id', label: 'TAX_INVOICE_ID' },
            { key: 'tax_invoice_id_9', label: 'TAX_INVOICE_ID' },
        ]
    },
    {
        title: 'SUPPLIER_INFO',
        key: 'supplier',
        items: [
            { key: 'supplier_name', label: 'SUPPLIER_NAME' },
            { key: 'supplier_vat', label: 'SUPPLIER_VAT' }
        ]
    },
    {
        title: 'CUSTOMER_INFO',
        key: 'customer',
        items: [
            { key: 'customer_name', label: 'CUSTOMER_NAME' },
            { key: 'customer_vat', label: 'CUSTOMER_VAT' },
            { key: 'customer_order_number', label: 'CUSTOMER_ORDER_NUMBER' },
            { key: 'shipping_address', label: 'SHIPPING_ADDRESS' },
            { key: 'billing_address', label: 'BILLING_ADDRESS' },
        ]
    },
    {
        title: 'OTHER_INFO',
        key: 'other',
    },
    {
        title: 'INVOICE_INFO',
        key: undefined,
        items: [
            { key: 'currency', label: 'CURRENCY' },
            { key: 'total_before_discount', label: 'TOTAL_BEFORE_DISCOUNT', type: 'amount' },
            { key: 'discount_amount', label: 'DISCOUNT_AMOUNT', type: 'amount' },
            { key: 'discount_percentage', label: 'DISCOUNT_PERCENTAGE' },
            { key: 'rounding', label: 'ROUNDING', type: 'amount' },
            { key: 'total_before_vat', label: 'TOTAL_BEFORE_VAT' , type: 'amount' },
            { key: 'vat_percentage', label: 'VAT_PERCENTAGE' },
            { key: 'vat_amount', label: 'VAT_AMOUNT' , type: 'amount' },
            { key: 'total_to_pay', label: 'TOTAL_TO_PAY' , type: 'amount' },
            { key: 'payment_terms', label: 'PAYMENT_TERMS' },
        ]
    },
    {
        title: 'NOTES',
        key: undefined,
        items: [
            { key: 'comments', label: 'NOTES' }
        ]
    },
    {
        title: 'ITEMS',
        key: 'items',
        items: [
            { key: 'catalog_number', label: 'CATALOG_NUMBER' },
            { key: 'barcode', label: 'BARCODE' },
            { key: 'serial_number', label: 'SERIAL_NUMBER' },
            { key: 'description', label: 'DESCRIPTION' },
            { key: 'unit_price', label: 'UNIT_PRICE' , type: 'amount'},
            { key: 'currency', label: 'CURRENCY' },
            { key: 'unit_type', label: 'UNIT_TYPE' },
            { key: 'discount_percentage', label: 'DISCOUNT_PERCENTAGE' },
            { key: 'discount_amount', label: 'DISCOUNT_AMOUNT' , type: 'amount'},
            { key: 'tax_rate', label: 'TAX_RATE' , type: 'amount'},
            { key: 'total', label: 'TOTAL', type: 'amount' },
        ]
    }
];

const getAllKeys = () => {
    const keys: any = {items: true};
    sections.forEach(section => {
        section.items?.forEach(item => {
            // @ts-ignore
            keys[item.key] = true;
        });
    })
    return keys;
}

export const allKeys = getAllKeys();

