import styled from "styled-components";

export const AppWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const AppContent = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-height: 1px;
  flex: 1;
`;
