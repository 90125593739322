import React, {useRef, useState} from "react";
import {
    DeleteIcon,
    FileContentRow, FileProgress,
    FileRow,
    FilesList,
    FileUploadWrapper,
    Title,
    UploadIcon,
    Wrapper
} from "./styles";
import uploadIcon from '../../resources/images/ic_upload.svg';
import deleteIcon from '../../resources/images/ic_delete.svg';
import {Button} from "../common/button";
import {useTranslation} from "react-i18next";

interface Props {
    data: { file: File, data: any }[];
    onStartClicked: (file: File[]) => void;
    onCancel: () => void;
    uploadProgress?: number;
}

const FileUpload: React.FC<Props> = (props: Props) => {
    const { data, onStartClicked, uploadProgress, onCancel } = props;
    const [files, setFiles] = useState<any[]>([]);
    const [isDragging, setIsDragging] = useState(false);
    const inputRef = useRef<any>();
    const {t} = useTranslation();

    const handleFileChange = (e: any) => {
        const selectedFiles = e.target.files;
        if (selectedFiles && selectedFiles.length > 0) {
            const newFiles = Array.from(selectedFiles);
            setFiles([...files, ...newFiles]);
        }
        setIsDragging(false);
    };

    const handleDrop = (e: any) => {
        e.preventDefault();
        const droppedFiles = e.dataTransfer.files;
        if (droppedFiles.length > 0) {
            const newFiles = Array.from(droppedFiles).filter((file: any) => file.type === 'application/pdf');
            setFiles([...files, ...newFiles]);
        }
        setIsDragging(false);
    };

    const onDeleteFile = (item: any) => {
        const copy = [...files];
        const index = copy.indexOf(item);
        if (index > -1) {
            copy.splice(index, 1);
        }
        setFiles(copy);
    }

    return <Wrapper>
        <FileUploadWrapper
            onClick={() => inputRef.current?.click()}
            onDrop={handleDrop}
            onDragOver={e => {
                e.preventDefault();
                setIsDragging(true);
            }}
            onDragLeave={e => {
                e.preventDefault();
                setIsDragging(false);
            }}
        >
            <UploadIcon isDragging={isDragging} src={uploadIcon}/>
            <Title>{t('UPLOAD_MESSAGE')}</Title>
            <input
                ref={inputRef}
                type="file"
                hidden
                id="browse"
                onChange={handleFileChange}
                accept=".pdf"
                multiple
            />
        </FileUploadWrapper>
        <FilesList>
            {
                files.map(item => <FileRow>
                    <FileContentRow>
                        <div>{item.name}</div>
                        <DeleteIcon src={deleteIcon} onClick={() => onDeleteFile(item)} />
                    </FileContentRow>
                    <FileProgress />
                </FileRow>)
            }
        </FilesList>
        { files.length > 0 && <Button label={
            t(files.length === 1 ? 'SHOW_FILE' : 'SHOW_FILES')
        } onClick={() => onStartClicked(files)}/> }
        { data?.length > 0 && files.length === 0 && <Button label={t('CANCEL')} onClick={() => onCancel()}/> }
    </Wrapper>
}

export default FileUpload;
