const colors = {
    theme: {
        galilGreen: 'rgb(161,198,53)',
        galilGreen80: 'rgba(161,198,53,0.8)',
        galilGreen10: 'rgba(161,198,53,0.1)',
        galilGrey:  'rgb(113,116,139)',
        galilGrey10:  'rgba(113,116,139, 0.1)',
        galilGrey30:  'rgba(113,116,139, 0.3)',
        galilBlue:  'rgb(10,174,209)',
        galilBlue80:  'rgba(10,174,209, 0.8)',
        galilBlue10:  'rgba(10,174,209, 0.1)',
        galilDark:  'rgb(42,46,57)',
        galilWhite: 'rgb(255,255,255)',
        galilOrange: '#EA8717',
    },
    prompt: {
        errorBackground: 'rgb(254,246,246)',
        errorBorder: 'rgb(252,218,218)',
        errorText: 'rgb(245,80,82)',
        errorSubText: 'rgb(99,107,123)'
    }
};

export default colors;
