import i18next from 'i18next';
import he from '../i18n/translations/he.json';

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'he',
    resources: {
        he: { translation : he},
    }
});

export default i18next;
