import styled from "styled-components";
import colors from "../../styles/colors";

export const LayoutWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const LayoutHorizontalWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export const LayoutContentWrapper = styled.div`
  box-sizing: border-box;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const AppContent = styled.div`
  width: 100%;
  min-height: 1px;
  flex: 1;
  overflow-y: auto;
`;

