import {put, takeLatest, call} from "redux-saga/effects";
import {userActions} from "./reducer";
import {PayloadAction} from "@reduxjs/toolkit";
import api from "../../network/api";
import {StorageKey} from "../storage";

function* loginUser(action: PayloadAction<{ username: string; password: string; }>) {
    try {
        // @ts-ignore
        const res = yield call(api.login, action.payload.username,  action.payload.password);
        if(res) {
            localStorage.setItem(StorageKey.NAME, action.payload.username);
            yield put(userActions.loginSuccess({token: res}));
        } else {
            yield put(userActions.loginFailure(res));
        }
    } catch (e: any) {
        yield put(userActions.loginFailure(e.message));
    }
}

function* signUpUser(action: PayloadAction<{ name: string; phone: string; email: string; }>) {
    try {
        // @ts-ignore
        const res = yield call(api.signup, action.payload.email, action.payload.phone, action.payload.name);
        if(res) {
            yield put(userActions.registerSuccess({token: res}));
        } else {
            yield put(userActions.registerFailure(res));
        }
    } catch (e: any) {
        yield put(userActions.registerFailure(e.message));
    }
}

function* loadUserData(action: PayloadAction<{}>) {
    try {
        // @ts-ignore
        const quota = yield call(api.getQuota);
        if(!quota) {
            yield put(userActions.loadDataFailure({error: 'ERROR_LOADING_QUOTA'}));
            return;
        }
        // @ts-ignore
        const userData = yield call(api.getUserData);
        if(userData) {
            yield put(userActions.loadDataSuccess({quota, userData}));
        } else {
            yield put(userActions.loadDataFailure({error: 'ERROR_LOADING_USER'}));
        }
    } catch (e: any) {
        yield put(userActions.loadDataFailure(e.message));
    }
}

export default function* userSaga() {
    yield takeLatest(userActions.login.type, loginUser);
    yield takeLatest(userActions.register.type, signUpUser);
    yield takeLatest(userActions.loadData.type, loadUserData);
}
