import styled from "styled-components";
import colors from "../../styles/colors";

export const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
`;

export const Toolbar = styled.div<{selected?: boolean}>`
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.selected ? colors.theme.galilGrey : colors.theme.galilDark};
  padding: 0.4rem 1rem;
`;

export const Actions = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  gap: 1rem;
`;

export const Table = styled.div`
  width: 100%;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;    
  border-bottom: 1px solid ${colors.theme.galilGrey10};
  height: 34px;
  font-weight: 700;
`;

export const Cell = styled.div<{checkbox?: boolean; header?: boolean; span?: number;}>`
  ${props => props.checkbox ? 'width: 48px' : 'flex: 1'};
  box-sizing: border-box;
  display: flex;
  line-height: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: ${colors.theme.galilDark};
  font-size: 1rem;
  font-weight: ${props => props.header ? 600 : 500};
  text-align: right;
  padding: 0.5rem;
  ${props => props.span ? `flex: ${props.span};`: ''}
  cursor: ${props => props.header ? 'pointer' : 'initial'};
  ${props => props.header ? 'user-select: none;' : ''}
`;

export const Title = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.2rem;
  color: white;
`;

export const Row = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;    
`;

export const contentSx = {display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '10px'};
