import styled from "styled-components";
import colors from "../../styles/colors";

export const StatsWrapper = styled.div`
  width: 100%;
  flex: 1;
  overflow-y: auto;
  min-height: 1px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px;
  font-size: 1.4rem;
  font-weight: 500;
  color: ${colors.theme.galilDark};
  border-bottom: 1px solid #F2F2F2;
`;

export const Card = styled.div<{span?: number;}>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 12px;
  padding: 20px;
  border: 1px solid #F2F2F2;
  min-width: 1px;
  min-height: 340px;
  box-sizing: border-box;
  ${props => props.span ? `flex: ${props.span};` : ''}
`;
