import styled from "styled-components";
import colors from "../../styles/colors";

export const SidebarWrapper = styled.div`
  box-sizing: border-box;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.theme.galilDark};
  box-shadow: 2px 0 4px 0 rgba(0,0,0,.2);
`;

export const SidebarSection = styled.div`
  box-sizing: border-box;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1.3rem;
`;

export const Logo = styled.img`
    height: 32px;
`;

export const Menu = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.8rem;
  padding-top: 40px;
`;

export const MenuTitle = styled.div`
  user-select: none;
  width: 100%;
  box-sizing: border-box;
  text-align: right;
  font-size: 0.85rem;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  color: ${colors.theme.galilWhite};
`;

export const MenuItem = styled.div<{selected?: boolean;}>`
  user-select: none;
  width: 100%;
  box-sizing: border-box;
  font-size: 1rem;
  color: ${props => props.selected ? colors.theme.galilGreen : colors.theme.galilWhite };
  font-weight: ${props => props.selected ? 500 : 500};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.3rem;
  padding: 0.4rem 1rem;
  transition: linear 0.3s;
  ${props => props.selected ?
  `background: rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);` : 'cursor:  pointer; border: 1px solid transparent;'}
  
  &:hover {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  svg {
    width: 1.2rem;
    height: 1.2rem;
  }
`;
