import {AppState} from "../types";

export const selectUserName = (store: AppState) => store.user.name;
export const selectIsLoading = (store: AppState) => store.user.isLoading;
export const selectIsLoggedIn = (store: AppState) => store.user.isLoggedIn;
export const selectRegisterSuccess = (store: AppState) => store.user.registerSuccess;
export const selectSessionTimeout = (store: AppState) => store.user.sessionTimeout;
export const selectName = (store: AppState) => store.user.name;
export const selectQuota = (store: AppState) => store.user.quota;
export const selectError = (store: AppState) => store.user.error;
export const selectUserData = (store: AppState) => store.user.userData;
export const selectMetadata = (store: AppState) => store.user.metadata;
