import styled from "styled-components";
import colors from "../../styles/colors";

export const ErrorWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  font-size: 1.3rem;
  font-weight: 600;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 30px;
  color: ${colors.prompt.errorText}
`;
