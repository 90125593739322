import React from 'react';
import {StyledInput} from "./styles";

interface Props {
    value: string;
    type?: string;
    textAlign?: 'right' | 'left';
    onChange: (e: any) => void;
    small?: boolean;
    border?: boolean;
    error?: boolean;
    placeholder?: string;
    style?: any;
}

const Input: React.FC<Props> = (props: Props) => {
    const {value, onChange, type, textAlign, small, placeholder, border, style, error} = props;
    return (<StyledInput
        style={style}
        error={error}
        placeholder={placeholder}
        small={small} textAlign={textAlign} type={type} value={value} onChange={onChange} border={border} />)
}

export default Input;
