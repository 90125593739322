import styled from "styled-components";
import colors from "../../styles/colors";

export const DashboardPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
`;

export const Toolbar = styled.div<{selected?: boolean}>`
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.selected ? colors.theme.galilGrey : colors.theme.galilDark};
  padding: 0.4rem 1rem;
`;

export const Title = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.2rem;
  color: white;
`;

