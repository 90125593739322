import React, {useEffect, useState} from 'react';
import GlobalStyle from "../styles/global-styles";
import {AppContent, AppWrapper} from './styles';
import {Provider} from "react-redux";
import store from "../store/store";
import {RouterProvider} from "react-router-dom";
import { appRouter, appRouterBusiness } from "../routes/routes";
import {ThemeProvider} from "@mui/material";
import theme from "../styles/theme";
import {Footer} from "../components/footer";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {I18nextProvider} from 'react-i18next';
import api from "../network/api";
import {Router as RemixRouter} from "@remix-run/router/dist/router";
import {userActions} from "../store/user";

interface Props {
    i18n: any;
}

const App: React.FC<Props> = (props: Props) => {
    const { i18n } = props;
    const [routes, setRoutes] = useState<RemixRouter | null>(null);

    useEffect(() => {
        api.getMetadata().then(metadata => {
            if(metadata) {
                store.dispatch(userActions.getMetadataSuccess(metadata));
            }
            if(metadata?.is_business) {
                setRoutes(appRouterBusiness)
            } else {
                setRoutes(appRouter)
            }
        }).catch(e => {
            setRoutes(appRouter)
        })
    }, []);

    if(!routes) {
        return null;
    }

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <I18nextProvider i18n={i18n}>
                    <Provider store={store}>
                        <AppWrapper>
                            <AppContent>
                                <RouterProvider router={routes}/>
                            </AppContent>
                            <Footer/>
                            <GlobalStyle/>
                        </AppWrapper>
                    </Provider>
                </I18nextProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
}

export default App;
