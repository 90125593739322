import React from "react";
import {AppFooter, Center, Side} from "./styles";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectIsLoggedIn, selectMetadata} from "../../store/user/selectors";

const year = (new Date()).getFullYear();

const Footer: React.FC = () => {
    const {t} = useTranslation();
    const metadata = useSelector(selectMetadata);
    const isLoggedIn = useSelector(selectIsLoggedIn);

    return <AppFooter>
        <Side dir="rtl">
            {!isLoggedIn && metadata?.version ? t('VERSION_STRING', {version: metadata.version}) : ''}
        </Side>
        <Center>
            <a href="mailto: billbot@galilsoftware.com">{t('CONTACT_US')}</a>
            <span>Galil Software LTD {year}</span>
        </Center>
        <Side />
    </AppFooter>
}

export default Footer;
