import {createBrowserRouter, Navigate} from "react-router-dom";
import {LoginPage, MainPage} from "../pages";
import {PrivateRoute} from "../components/private-route";
import {RegisterPage} from "../pages/register";
import { Route } from "./Route";
import {UploadPage} from "../pages/upload";
import UsersPage from "../pages/users/UsersPage";
import {DashboardPage} from "../pages/dashboard";
import { FeatureTogglesPage } from "../pages/toggles";
import {TokensPage} from "../pages/TokensPage";
import {BusinessSettingsPage} from "../pages/business";

const baseRoutes = [
    {
        path: `/${Route.Login}`,
        element: <LoginPage />
    },
    {
        path: '/',
        // @ts-ignore
        element: <PrivateRoute key="main-page-route"><MainPage key="main-page"/></PrivateRoute>,
        children: [
            { path: `/${Route.Upload}`, element: <UploadPage /> },
            { path: `/${Route.Dashboard}`, element: <DashboardPage /> },
            { path: `/${Route.Users}`, element: <UsersPage /> },
            { path: `/${Route.Tokens}`, element: <TokensPage /> },
            { path: `/${Route.FeatureToggles}`, element: <FeatureTogglesPage /> },
            { path: `/${Route.BusinessSettings}`, element: <BusinessSettingsPage /> },
            { path: '/', element: <UploadPage /> },
            { path: '*', element: <Navigate to={`/${Route.Upload}`} replace /> }
        ]
    },
];

export const appRouter = createBrowserRouter([{
    path: `/${Route.Register}`,
    element: <RegisterPage />
}, ...baseRoutes]);

export const appRouterBusiness = createBrowserRouter(baseRoutes);
