import React, {useEffect} from "react";
import { UsersPageWrapper } from "./styles";
import UsersTable from "../../components/users/UsersTable";
import {useDispatch, useSelector} from "react-redux";
import {selectUsersList, adminActions} from "../../store/admin";
import {selectAdminLoading} from "../../store/admin/selectors";
import {Loader} from "../../components/loader";


const UsersPage: React.FC = () => {
    const users = useSelector(selectUsersList);
    const loading = useSelector(selectAdminLoading);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(adminActions.getUsersList());
    }, []);

    return <UsersPageWrapper id="users-page">
        <UsersTable users={users} />
        <Loader show={loading} />
    </UsersPageWrapper>
}

export default UsersPage;
