import styled from "styled-components";
import colors from "../../styles/colors";


export const Wrapper = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  background-color: white;
  gap: 8px;
`;

export const FilesList = styled.div`
  width: 60%;
  max-height: 390px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 12px;
  gap: 8px;
`;

export const FileRow = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: start;
  border-radius: 4px;
  border: 1px solid ${colors.theme.galilGreen};
  box-sizing: border-box;
  padding: 16px 16px 12px 16px;
  color: ${colors.theme.galilGreen};
  &:hover {
    background-color: ${colors.theme.galilGreen10};
  }
`;

export const FileProgress = styled.div<{percentage?: number;}>`
  width: ${props => props.percentage || 0}%;
  height: 4px;
  background-color: ${colors.theme.galilBlue};
`;

export const FileContentRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const FileUploadWrapper = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  border-radius: 12px;
  background-color: white;
  border: 2px dashed ${colors.theme.galilDark};
  cursor: pointer;
  transition: background-color ease-in 0.1s;
  box-sizing: border-box;
  &:hover {
    background-color: ${colors.theme.galilGrey10};
  }
`;

export const UploadIcon = styled.img<{isDragging: boolean;}>`
  width: 120px;
  color: ${colors.theme.galilGreen};
  scale: ${props => props.isDragging ? 1.1 : 1};
  rotate: ${props => props.isDragging ? '10deg' : '0deg'};
  transition: 0.4s ease-in-out;
`;

export const DeleteIcon = styled.img`
  width: 24px;
  cursor: pointer;
  filter: invert(70%) sepia(67%) saturate(436%) hue-rotate(30deg) brightness(92%) contrast(91%);
  &:hover {
    filter: invert(56%) sepia(63%) saturate(3701%) hue-rotate(155deg) brightness(98%) contrast(92%);
  }
`;

export const Title = styled.span`
  font-size: 1.1rem;
  color: ${colors.theme.galilGreen};
`;
