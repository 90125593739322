import styled from "styled-components";
import colors from "../../styles/colors";

export const UploadPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
`;

export const UploadPageSections = styled.div`
  height: calc(100% - 40px);
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
`;

export const Navigation = styled.div`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${colors.theme.galilGrey};
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
  z-index: 200;
`;

export const NavigationPart = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const NavigationWrapper = styled.div`
  display: flex;
  height: 28px;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  background-color: white;
  border: 1px solid ${colors.theme.galilGrey30};
  span {
    font-size: 0.9rem;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const Next = styled.div<{disabled?: boolean;}>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  font-size: 0.9rem;
  ${props => props.disabled ? '' : 'cursor: pointer;'}
  border-right: 1px solid ${colors.theme.galilGrey30};
  user-select: none;
  &:hover {
    background-color: ${props => props.disabled ? 'transparent' : colors.theme.galilGrey10};
  }
`;

export const Previous = styled.div<{disabled?: boolean;}>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  font-size: 0.9rem;
  ${props => props.disabled ? '' : 'cursor: pointer;'}
  border-left: 1px solid ${colors.theme.galilGrey30};
  user-select: none;
  &:hover {
    background-color: ${props => props.disabled ? 'transparent' : colors.theme.galilGrey10};
  }
`;
