import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {Input} from "../common";
import {Button} from "../common/button";
import {contentSx} from './styles';

interface Props {
    open: boolean;
    onClose: () => void;
    onAddToken: (name: string, folder: string) => void;
}

const AddTokenDialog: React.FC<Props> = (props) => {
    const {open, onClose, onAddToken} = props;

    const [name, setName] = useState('');
    const [folder, setFolder] = useState('');
    const {t} = useTranslation();

    return (<Dialog open={open} dir="rtl">
        <DialogTitle>{t('ADD_TOKEN')}</DialogTitle>
        <DialogContent sx={contentSx}>
            <span>{t('TOKEN_NAME')}</span>
            <Input border value={name} onChange={e => setName(e.target.value)} type="text" />
            <span>{t('FOLDER_NAME')}</span>
            <Input border value={folder} onChange={e => setFolder(e.target.value)} type="text" />
        </DialogContent>
        <DialogActions sx={{gap: '4px'}}>
            <Button narrow label={t('CANCEL')} onClick={onClose} />
            <Button narrow label={t('APPROVE')} onClick={() => {
                if(name && folder) {
                    onAddToken(name, folder);
                }
            }} />
        </DialogActions>
    </Dialog>);
}

export default AddTokenDialog;
