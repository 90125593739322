import React, {useMemo} from 'react';
import {
    ValuesList,
    Table,
    Row,
    Cell,
    CardList,
    CardRow,
    CardKey,
    CardValue,
    Title,
    LoadingText,
    PreviewTitle, PreviewSubtitle
} from "./styles";
import {CircularProgress, FormControlLabel, Switch} from "@mui/material";
import {sections, allKeys} from "./sections";
import { Button } from '../common/button';
import {useTranslation} from "react-i18next";
import {Input} from "../common";
import {Toast} from "../common/toast";

interface Props {
    fileName?: string;
    data: any;
    editMode: boolean;
    toggleEditMode: () => void;
    loading?: boolean;
    fileLoadError?: boolean;
    onStartUpload: () => void;
    onChange: (key: string, value: string) => void;
    onItemChange: (index: number, key: string, value: string) => void;
}

const formatAmount = (obj: any) =>{
    if(obj === undefined || obj === null) return '-';
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'ILS',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
        useGrouping: true
    });
    return formatter.format(obj).replace('₪', '');
}

const getRow = (obj: any, type: string | undefined = undefined) => {
    if (Array.isArray(obj)) {
        let res = '';
        obj.forEach(item => {
            res += `${getRow(item)} `;
        })
        return res;
    }
    if (typeof obj === 'object') {

    }
    if(type === 'amount'){
        return formatAmount(obj);
    }
    return obj;
}

const PdfData: React.FC<Props> = (props: Props) => {
    const {data, loading, fileName, fileLoadError, onStartUpload, editMode, toggleEditMode, onChange, onItemChange} = props;
    const {t} = useTranslation();

    const itemKeys = useMemo(() => {
        const availableKeys: { key: string; label: string; type?: string; }[] = [];
        if(data?.result?.items && data.result.items.length > 0) {
            data.result.items.forEach((item: any) => {
                Object.keys(item).forEach(key => {
                    if(-1 === availableKeys.findIndex(x => x.key === key)) {
                        availableKeys.push({key, label: key});
                    }
                });
            });
        }
        return availableKeys;
    }, [data]);

    const getSectionRow = (section: { title: string; key?: string; items?: { key: string; label: string; type?: string; }[] }) => {
        if (!data?.result) return null;

        if (section.key === 'items') {
            if (data.result.items && data.result.items.length > 0) {
                return (<>
                    <Title>{t(section.title)}</Title>
                    <Table>
                        <Row header>
                            {
                                itemKeys.map(header => <Cell>
                                    <CardKey>
                                        {header.key.replaceAll('_', ' ')}
                                    </CardKey>
                                </Cell>)
                            }
                        </Row>
                        {
                            data.result.items.map((item: any, i: number) => <Row key={`row-${i}`}>
                                {
                                    itemKeys.map(key => <Cell key={`cell-${key.key}-${i}`}>
                                        <CardValue>
                                            {editMode ? <Input
                                                border
                                                style={{minWidth: 0}}
                                                value={item[key.key]}
                                                onChange={e => onItemChange(i, key.key, e.target.value)}
                                            /> : (key.type === 'amount' ?
                                                formatAmount(item[key.key])
                                                :
                                                item[key.key])}
                                        </CardValue>
                                    </Cell>)
                                }
                            </Row>)
                        }
                    </Table>
                </>)
            } else {
                return null;
            }
        }

        if (section.key === 'other') {
            const others: string[] = [];
            Object.keys(data.result).forEach(key => {
                if (!allKeys[key]) {
                    others.push(key);
                }
            });
            if (others.length > 0) {
                return (<>
                    <Title>{t(section.title)}</Title>
                    {
                        others.map(key => (<CardRow>
                            <CardValue key={`val-${key}`}>
                                {editMode ? <Input
                                    border
                                    value={data.result[key]}
                                    onChange={e => onChange(key, e.target.value)}
                                /> : getRow(data.result[key])}
                            </CardValue>
                            <CardKey key={`key-${key}`}>
                                {key}
                            </CardKey>
                        </CardRow>))
                    }
                </>);
            }
            return null;
        }
        const rows: any[] = [];

        section.items?.forEach(item => {
            if (data.result[item.key] !== undefined && data.result[item.key] !== null) {
                rows.push((<CardRow>
                    <CardValue key={`val-${item.key}`}>
                        {editMode ? <Input
                            border
                            value={data.result[item.key]}
                            onChange={e => onChange(item.key, e.target.value)}
                        /> : getRow(data.result[item.key], item.type)}
                    </CardValue>
                    <CardKey key={`key-${item.key}`}>
                        {`(${item.key.replaceAll('_', ' ')}) ${t(item.label)}`}
                    </CardKey>
                </CardRow>));
            }
        });

        if (rows.length === 0) return null;

        return <>
            <Title>{t(section.title)}</Title>
            {rows}
        </>
    }

    return (<ValuesList loading={!!loading}>
        {!loading && !data && (!fileLoadError ? <CardList center>
            <PreviewTitle>{fileName}</PreviewTitle>
            <PreviewTitle>{t('PREVIEW')}</PreviewTitle>
            <PreviewSubtitle dir="rtl">{t('PREVIEW_MESSAGE')}</PreviewSubtitle>
            <Button
                narrow
                label={t('UPLOAD')}
                onClick={onStartUpload} />
        </CardList>
            :
        <CardList center>
            <PreviewTitle>{fileName}</PreviewTitle>
            <PreviewTitle>{t('FILE_LOADING_ERROR')}</PreviewTitle>
        </CardList>)
        }
        {!loading && data && <CardList>
            <Row justifyContent="space-between">
                <FormControlLabel
                    id="edit-mode-toggle-control"
                    sx={{ marginLeft: 0, marginRight: 0 }}
                    control={<Switch
                        id="edit-mode-toggle"
                        color="success"
                        checked={editMode}
                        onChange={(e, checked) => toggleEditMode()}
                        inputProps={{'aria-label': 'controlled'}}
                    />}
                    label={t('EDIT_MODE')}
                />
                <Title isMain>{fileName}</Title>
            </Row>
            {data?.error && <Toast message={data.error} title={t('ATTENTION')}/>}
            <Title>{t('RESULTS')}</Title>
            {
                sections.map(section => getSectionRow(section))
            }
        </CardList>}
        {loading && <>
            <CircularProgress/>
            <LoadingText>{t('LOADING_DATA')}</LoadingText>
        </>}
    </ValuesList>)
}

export default PdfData;
