export enum Route {
    Login = 'login',
    Register = 'register',
    Home = '',
    Upload = 'upload',
    Dashboard = 'dashboard',
    Users = 'users',
    Tokens = 'tokens',
    FeatureToggles = 'feature-toggles',
    BusinessSettings = 'business',
}
