import {Alert, AlertsState} from "../types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState: AlertsState = {
    alerts: []
}

export const alertsSlice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        enqueue: (state: AlertsState, action: PayloadAction<Alert>) => {
            state.alerts = [
                ...state.alerts,
                action.payload
            ];
        },
        pop: (state: AlertsState) => {
            if(state.alerts.length > 0) {
                const copy = [...state.alerts];
                copy.shift();
                state.alerts = copy;
            }
        },
    }
});

export const alertsActions = alertsSlice.actions;

export default alertsSlice.reducer;
