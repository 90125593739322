import {call, put, takeLatest} from "redux-saga/effects";
import {adminActions} from "./reducer";
import {alertsActions} from "../alerts";
import {PayloadAction} from "@reduxjs/toolkit";
import api from "../../network/api";
import {BaseFeatureToggle, FeatureToggle} from "../../types/FeatureToggle";
import {Customer} from "../../types/Customer";
import {userActions} from "../user";

function* loadUsers(action: PayloadAction<{}>) {
    try {
        // @ts-ignore
        const res = yield call(api.getUsersList);
        if(res) {
            yield put(adminActions.getUsersListSuccess({users: res}));
        } else {
            yield put(adminActions.getUsersListFailure(res));
        }
    } catch (e: any) {
        yield put(adminActions.getUsersListFailure(e.message));
    }
}

function* loadTokens(action: PayloadAction<{}>) {
    try {
        // @ts-ignore
        const res = yield call(api.getTokens);
        if(res) {
            yield put(adminActions.getTokensListSuccess({tokens: res.data}));
        } else {
            yield put(adminActions.getTokensListFailure(res));
        }
    } catch (e: any) {
        yield put(adminActions.getTokensListFailure(e.message));
    }
}

function* loadStatistics(action: PayloadAction<{startDate: number; endDate: number;}>) {
    try {
        // @ts-ignore
        const res = yield call(api.getStatistics, action.payload.startDate, action.payload.endDate);
        if(res) {
            yield put(adminActions.getStatisticsSuccess({stats: res}));
        } else {
            yield put(adminActions.getStatisticsFailure(res));
        }
    } catch (e: any) {
        yield put(adminActions.getStatisticsFailure(e.message));
    }
}

function* updateFeatureToggles(action: PayloadAction<{ add: BaseFeatureToggle[]; update: FeatureToggle[] }>) {
    try {
        for(let i=0; i < action.payload.update.length; i++) {
            // @ts-ignore
            const res = yield call(api.updateFeatureToggle, action.payload.update[i]);
            if(!res) {
                yield put(adminActions.updateFeatureTogglesFailure(res));
                yield put(alertsActions.enqueue({sevirity: 'error', text: 'ERROR_UPDATE_FAILED'}));
                return;
            }
        }
        for(let i=0; i < action.payload.add.length; i++) {
            // @ts-ignore
            const res = yield call(api.addFeatureToggle, action.payload.add[i]);
            if(!res) {
                yield put(adminActions.updateFeatureTogglesFailure(res));
                yield put(alertsActions.enqueue({sevirity: 'error', text: 'ERROR_ADD_FAILED'}));
                return;
            }
        }
        yield put(adminActions.getFeatureToggles());
        yield put(adminActions.updateFeatureTogglesSuccess());
    } catch (e: any) {
        yield put(adminActions.updateFeatureTogglesFailure(e.message));
    }
}

function* loadFeatureToggles() {
    try {
        // @ts-ignore
        const res = yield call(api.getFeatureToggles);
        if(res) {
            yield put(adminActions.getFeatureTogglesSuccess(res));
        } else {
            yield put(adminActions.getFeatureTogglesFailure(res));
        }
    } catch (e: any) {
        yield put(adminActions.getFeatureTogglesFailure(e.message));
    }
}

function* loadCustomerInfo() {
    try {
        // @ts-ignore
        const res = yield call(api.getCustomerInfo);
        if(res) {
            yield put(adminActions.getCustomerInfoSuccess(res.data));
        } else {
            yield put(adminActions.getCustomerInfoFailure(res.error));
        }
    } catch (e: any) {
        yield put(adminActions.getCustomerInfoFailure(e.message));
    }
}

function* updateCustomerInfo(action: PayloadAction<Partial<Customer>>) {
    try {
        // @ts-ignore
        const res = yield call(api.updateCustomerInfo, action.payload);
        if(res) {
            yield put(adminActions.updateCustomerInfoSuccess());
            yield put(adminActions.getCustomerInfo());
            yield put(userActions.loadData());
        } else {
            yield put(adminActions.updateCustomerInfoFailure(res.error));
        }
    } catch (e: any) {
        yield put(adminActions.updateCustomerInfoFailure(e.message));
    }
}

function* deleteToken(action: PayloadAction<{id: string}>) {
    try {
        // @ts-ignore
        const res = yield call(api.deleteToken,action.payload.id);
        if(res) {
            yield put(adminActions.deleteTokenSuccess());
            yield put(adminActions.getTokensList());
        } else {
            yield put(adminActions.deleteTokenFailure({error: ''}));
            yield put(alertsActions.enqueue({sevirity: 'error', text: 'ERROR_DELETE_TOKEN'}));
        }
    } catch(e: any) {
        yield put(adminActions.deleteTokenFailure(e.message));
        yield put(alertsActions.enqueue({sevirity: 'error', text: 'ERROR_DELETE_TOKEN'}));
    }
}

function* addToken(action: PayloadAction<{name: string; folder: string;}>) {
    try {
        // @ts-ignore
        const res = yield call(api.addToken,action.payload.name, action.payload.folder);
        if(res) {
            yield put(adminActions.addTokenSuccess());
            yield put(adminActions.getTokensList());
        } else {
            yield put(adminActions.addTokenFailure({error: ''}));
            yield put(alertsActions.enqueue({sevirity: 'error', text: 'ERROR_ADD_TOKEN'}));
        }
    } catch(e: any) {
        console.log(e);
        yield put(adminActions.addTokenFailure(e.message));
        yield put(alertsActions.enqueue({sevirity: 'error', text: 'ERROR_ADD_TOKEN'}));
    }
}

function* increaseQuota(action: PayloadAction<{userId: string; quota: number;}>) {
    try {
        // @ts-ignore
        let res = yield call(api.increaseUserQuota, action.payload.userId, action.payload.quota);
        if(res) {
            // @ts-ignore
            res = yield call(api.getUsersList);
            if(res) {
                yield put(adminActions.addQuotaSuccess({users: res}));
                yield put(alertsActions.enqueue({sevirity: 'success', text: 'SUCCESS_QUOTA_UPDATE'}));
            } else {
                yield put(adminActions.addQuotaFailure(res));
                yield put(alertsActions.enqueue({sevirity: 'error', text: 'ERROR_FETCHING_USERS_FAILED'}));
            }
        } else {
            yield put(adminActions.addQuotaFailure(res));
            yield put(alertsActions.enqueue({sevirity: 'error', text: 'ERROR_QUOTA_UPDATE'}));
        }
    } catch (e: any) {
        yield put(adminActions.addQuotaFailure(e.message));
        yield put(alertsActions.enqueue({sevirity: 'error', text: 'ERROR_QUOTA_UPDATE'}));
    }
}

function* updateUserStatus(action: PayloadAction<{userId: string; isDisabled: boolean;}>) {
    try {
        // @ts-ignore
        let res = yield call(api.updateUserStatus, action.payload.userId, action.payload.isDisabled);
        if(res) {
            // @ts-ignore
            res = yield call(api.getUsersList);
            if(res) {
                yield put(adminActions.updateUserStatusSuccess({users: res}));
                yield put(alertsActions.enqueue({sevirity: 'success', text: 'SUCCESS_USER_UPDATE'}));
            } else {
                yield put(adminActions.updateUserStatusFailure(res));
                yield put(alertsActions.enqueue({sevirity: 'error', text: 'ERROR_FETCHING_USERS_FAILED'}));
            }
        } else {
            yield put(adminActions.updateUserStatusFailure(res));
            yield put(alertsActions.enqueue({sevirity: 'error', text: 'ERROR_USER_UPDATE'}));
        }
    } catch (e: any) {
        yield put(adminActions.updateUserStatusFailure(e.message));
        yield put(alertsActions.enqueue({sevirity: 'error', text: 'ERROR_USER_UPDATE'}));
    }
}

function* resetUserPassword(action: PayloadAction<{userId: string}>) {
    try {
        // @ts-ignore
        let res = yield call(api.resetUserPassword, action.payload.userId);
        if(res) {
            yield put(adminActions.resetUserPasswordSuccess());
            yield put(alertsActions.enqueue({sevirity: 'success', text: 'SUCCESS_PASSWORD_RESET'}));
        } else {
            yield put(adminActions.resetUserPasswordFailure(res));
            yield put(alertsActions.enqueue({sevirity: 'error', text: 'ERROR_PASSWORD_RESET'}));
        }
    } catch (e: any) {
        yield put(adminActions.resetUserPasswordFailure(e.message));
        yield put(alertsActions.enqueue({sevirity: 'error', text: 'ERROR_PASSWORD_RESET'}));
    }
}

export default function* adminSaga() {
    yield takeLatest(adminActions.getUsersList.type, loadUsers);
    yield takeLatest(adminActions.getTokensList.type, loadTokens);
    yield takeLatest(adminActions.addQuota.type, increaseQuota);
    yield takeLatest(adminActions.deleteToken.type, deleteToken);
    yield takeLatest(adminActions.addToken.type, addToken);
    yield takeLatest(adminActions.updateUserStatus.type, updateUserStatus);
    yield takeLatest(adminActions.resetUserPassword.type, resetUserPassword);
    yield takeLatest(adminActions.getStatistics.type, loadStatistics);
    yield takeLatest(adminActions.getFeatureToggles.type, loadFeatureToggles);
    yield takeLatest(adminActions.getCustomerInfo.type, loadCustomerInfo);
    yield takeLatest(adminActions.updateCustomerInfo.type, updateCustomerInfo);
    yield takeLatest(adminActions.updateFeatureToggles.type, updateFeatureToggles);
}
