import styled from "styled-components";
import colors from "../../../styles/colors";

export const ToastWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: ${colors.prompt.errorBackground};
  border: 1px solid ${colors.prompt.errorBorder};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px;
  //position: fixed;
  //right: 60px;
  //top: 60px;
  border-radius: 8px;
  img {
    filter: invert(39%) sepia(20%) saturate(1888%) hue-rotate(303deg) brightness(113%) contrast(101%);
    width: 24px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`;

export const Title = styled.span`
  font-size: 1.2rem;
  font-weight: 500;
  color: ${colors.prompt.errorText};
`;

export const SubTitle = styled.span`
  font-size: 1.1rem;
  font-weight: 400;
  color: ${colors.prompt.errorSubText};
  padding-right: 28px;
`;
