import React, {useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {Input} from "../common";
import {Button} from "../common/button";
import { contentSx } from "./styles";
import {useTranslation} from "react-i18next";

interface Props {
    userId?: string;
    onClose: () => void;
    onUpdateQuota: (userId: string, quota: number) => void;
}

const UpdateQuotaDialog: React.FC<Props> = (props: Props) =>{
    const { userId, onClose, onUpdateQuota } = props;
    const [quota, setQuota] = useState('');
    const {t} = useTranslation();

    return (<Dialog open={!!userId} dir="rtl">
        <DialogTitle>{t('UPDATE_QUOTA')}</DialogTitle>
        <DialogContent sx={contentSx}>
            <span>{t('QUOTA_TO_ADD')}</span>
            <Input border value={quota} onChange={e => setQuota(e.target.value)} type="number" />
        </DialogContent>
        <DialogActions sx={{gap: '4px'}}>
            <Button narrow label={t('CANCEL')} onClick={onClose} />
            <Button narrow label={t('APPROVE')} onClick={() => {
                if(userId) {
                    onUpdateQuota(userId, Number(quota));
                }
            }} />
        </DialogActions>
    </Dialog>);
}

export default UpdateQuotaDialog;
