import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import i18n from './i18n/i18n-he';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const urlSearchParams = new URLSearchParams(window.location.search);
if(urlSearchParams.get('email')) {
    sessionStorage.setItem('email', urlSearchParams.get('email') || '');
}
if(urlSearchParams.get('password')) {
    sessionStorage.setItem('password', urlSearchParams.get('password') || '');
}

root.render(
  // <React.StrictMode>
    <App i18n={i18n} />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
