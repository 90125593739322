import React from "react";
import {LoaderWrapper, Rect} from "./styles";

interface Props {
    show?: boolean;
}

const Loader: React.FC<Props> = (props: Props) => {
    const { show } = props;

    if(!show) return null;

    return (<LoaderWrapper>
        <Rect index={0} />
        <Rect index={1} />
        <Rect index={2} />
    </LoaderWrapper>);
}

export default Loader;
