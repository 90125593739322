import styled from "styled-components";
import colors from "../../styles/colors";

export const BusinessSettingsPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
`;

export const Toolbar = styled.div`
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.theme.galilDark};
  padding: 0.4rem 1rem;
`;

export const List = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  overflow-y: auto;
  padding: 20px;
`;

export const KeyValPair = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
  font-size: 1.2rem;
  color: ${colors.theme.galilDark};
  gap: 20px;
`;

export const Key = styled.div`
  font-size: 1.2rem;
  color: ${colors.theme.galilDark};
  width: 300px;
`;
