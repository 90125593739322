import styled from 'styled-components';
import colors from '../../styles/colors';

export const FeatureToggleWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 6px;
  border: 1px solid ${colors.theme.galilGrey30};
  margin-top: 10px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  font-size: 1.2rem;
  font-weight: 600;
  color: ${colors.theme.galilDark};
  .title {
    width: 300px;
    text-align: right;
  }
`;
