import React, {useEffect, useRef, useState} from "react";
import {Token} from "../../types/Token";
import {Actions, Cell, Header, Table, TableWrapper, Toolbar, Row, Title} from "./styles";
import {useTranslation} from "react-i18next";
import {Checkbox, TableSortLabel, Tooltip} from "@mui/material";
import {FixedSizeList} from "react-window";
import Input from "../common/input/Input";
import {IconButton} from "../users/styles";
import {Add, Delete, Password} from "@mui/icons-material";
import ValidationDialog from "../validation-dialog/ValidationDialog";
import {adminActions} from "../../store/admin";
import {useDispatch} from "react-redux";
import AddTokenDialog from "./AddTokenDialog";

interface Props {
    tokens: Token[];
}

const isMultiselect = false;
type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const TokensTable: React.FC<Props> = (props: Props) => {
    const {tokens} = props;
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const wrapperRef = useRef<any>();
    const [wrapperRefState, setWrapperRefState] = useState<any>();
    const [selected, setSelected] = useState<readonly string[]>([]);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof Token>('token_name');
    const [search, setSearch] = useState('');
    const [showDeleteToken, setShowDeleteToken] = useState(false);
    const [showAddToken, setShowAddToken] = useState(false);
    const [selectedSingle, setSelectedSingle] = useState<Token | undefined>(undefined);

    const numSelected = selected.length;
    const rowCount = tokens.length;

    useEffect(() => {
        setWrapperRefState(wrapperRef.current);
    }, [wrapperRef.current]);

    const headCells: {id: keyof Token; label: string; span: number; formatValue?: (token: Token) => string}[] = [
        {
            id: 'token_name',
            label: t('TOKEN_NAME'),
            span: 1.5
        },
        {
            id: 'folder_name',
            label: t('FOLDER_NAME'),
            span: 2
        },
        {
            id: '_id',
            label: t('TOKEN'),
            span: 2
        }
    ];

    const createSortHandler = (property: keyof Token) => (event: React.MouseEvent<unknown>) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const onSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(!isMultiselect) return;
        if (event.target.checked) {
            const newSelected = tokens.map(token => token._id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    const rows = React.useMemo(
        // @ts-ignore
        () => search === '' ? tokens.slice().sort(getComparator(order, orderBy)) :
            // @ts-ignore
            tokens.slice().sort(getComparator(order, orderBy)).filter(item => item.token_name.includes(search) ||
                    item.folder_name.includes(search) || item._id.includes(search)),
        [order, orderBy, tokens, search],
    );

    const handleClick = (event: any, id: string) => {
        const selectedIndex = selected.indexOf(id);
        if(!isMultiselect) {
            const single = tokens.findIndex(item => item._id === id);
            setSelectedSingle(selectedIndex === -1 ? tokens[single] : undefined);
            setSelected(selectedIndex === -1 ? [id] : []);
        } else {
            const copy = [...selected];
            if (selectedIndex === -1) {
                copy.push(id);
            } else {
                copy.splice(selectedIndex, 1);
            }
            setSelected(copy);
        }
    };

    const onDeleteTokenClicked = () => {
        setShowDeleteToken(true);
    }

    const onDeleteApproved = () => {
        if(selectedSingle?._id) {
            dispatch(adminActions.deleteToken({id: selectedSingle._id}));
            setShowDeleteToken(false);
        }
    }

    const onAddTokenClicked = () => {
        setShowAddToken(true);
    }

    const onAddApproved = (name: string, folder: string) => {
        dispatch(adminActions.addToken({name, folder}));
        setShowAddToken(false);
    }

    return <TableWrapper id="tokens-table">
        <Toolbar>
            <Actions>
                <Input
                    small
                    textAlign="right"
                    placeholder={t('SEARCH')}
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                />
                {selected.length === 1 && <Tooltip title={t('DELETE_TOKEN')}>
                    <IconButton onClick={onDeleteTokenClicked}>
                        <Delete />
                    </IconButton>
                </Tooltip>}
                {selected.length === 0 && <Tooltip title={t('ADD_TOKEN')}>
                    <IconButton onClick={onAddTokenClicked}>
                        <Add />
                    </IconButton>
                </Tooltip>}
            </Actions>
            <Title>{t('TOKENS_PAGE')}</Title>
        </Toolbar>
        <Table dir="rtl" ref={wrapperRef}>
            <Header>
                <Cell checkbox><Checkbox
                    checked={numSelected > 0}
                    onChange={onSelectAll}
                    size="small"
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                /></Cell>
                {headCells.map(cell => <Cell header span={cell.span}>
                    <TableSortLabel
                        active={orderBy === cell.id}
                        direction={orderBy === cell.id ? order : 'asc'}
                        onClick={createSortHandler(cell.id)}
                    >
                        {cell.label}
                    </TableSortLabel>
                </Cell>)}
            </Header>
            <FixedSizeList
                itemData={rows}
                height={(wrapperRefState?.clientHeight || 0) - 34}
                width="100%"
                itemSize={30}
                itemCount={rows.length}>
                {({ data, index, style }) => (<Row dir="rtl" style={style}>
                    <Cell checkbox>
                        <Checkbox
                            checked={isSelected(data[index]._id)}
                            onChange={e => handleClick(e, data[index]._id)}
                            size="small" />
                    </Cell>
                    {headCells.map(cell => <Cell span={cell.span}>
                        {/* @ts-ignore */}
                        {cell.formatValue ? cell.formatValue(data[index]) : data[index][cell.id]}
                    </Cell>)}
                </Row>)}
            </FixedSizeList>
        </Table>
        <AddTokenDialog
            open={showAddToken}
            onClose={() => setShowAddToken(false)}
            onAddToken={onAddApproved}
        />
        <ValidationDialog
            open={showDeleteToken}
            onClose={() => {
                setShowDeleteToken(false);
            }}
            onApprove={onDeleteApproved}
            title={t('DELETE_TOKEN_TITLE')}
            text={t('DELETE_TOKEN_CONFIRM_MESSAGE')}
        />
    </TableWrapper>
}

export default TokensTable;
