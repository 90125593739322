import React, {PropsWithChildren, useMemo} from "react";
import {AppContent, LayoutContentWrapper, LayoutHorizontalWrapper, LayoutWrapper} from "./styles";
import Toolbar from "../toolbar/Toolbar";
import {useDispatch, useSelector} from "react-redux";
import {selectIsLoggedIn} from "../../store/user";
import {Alert, Snackbar} from "@mui/material";
import {alertsActions, selectAlerts} from "../../store/alerts";
import {Sidebar} from "../sidebar";
import {selectUserData} from "../../store/user/selectors";
import {Role} from "../../types/Role";
import {useTranslation} from "react-i18next";

const Layout: React.FC<PropsWithChildren> = props => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const user = useSelector(selectUserData);
    const alerts = useSelector(selectAlerts);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const isAdmin = useMemo(() => -1 !== user?.roles?.findIndex(role => role === Role.SuperUser || role === Role.Admin),
        [user]);

    return (<LayoutWrapper id="app-layout">
        <LayoutHorizontalWrapper>
            <LayoutContentWrapper>
                {isLoggedIn && <Toolbar/>}
                <AppContent id="app-content">
                    {props.children}
                </AppContent>
            </LayoutContentWrapper>
            {isAdmin && <Sidebar/>}
        </LayoutHorizontalWrapper>
        <Snackbar
            open={alerts.length > 0}
            autoHideDuration={5000}
            onClose={() => dispatch(alertsActions.pop())}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        >
            {alerts.length > 0 ? <Alert
                onClose={() => dispatch(alertsActions.pop())}
                severity={alerts[0].sevirity}
                variant="filled"
                sx={{width: '100%'}}
            >
                {t(alerts[0].text)}
            </Alert> : undefined}
        </Snackbar>
    </LayoutWrapper>);
}

export default Layout;
