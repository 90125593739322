import i18next from 'i18next';
import ar from '../i18n/translations/ar.json';

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'ar',
    resources: {
        ar: { translation : ar},
    }
});

export default i18next;
