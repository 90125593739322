import React, {useEffect} from "react";
import {
    MainPageWrapper,
} from "./styles";
import api from "../../network/api";
import {useDispatch, useSelector} from "react-redux";
import {selectIsLoggedIn, userActions} from "../../store/user";
import {Outlet, useNavigate} from "react-router-dom";
import {Layout} from "../../components/layout";
import {Loader} from "../../components/loader";
import {selectIsLoading} from "../../store/user/selectors";

const MainPage: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const isLoading = useSelector(selectIsLoading);

    useEffect(() => {
        api.setOnLoggedOut(() => {
            dispatch(userActions.logout({sessionTimeout: true}));
        });
        if (!isLoggedIn) {
            navigate('/login', {replace: true})
        } else {
            dispatch(userActions.loadData());
        }
        return () => api.setOnLoggedOut(() => {});
    }, [dispatch, isLoggedIn]);

    return (<Layout>
        <MainPageWrapper key="main-page-wrapper">
            <Outlet/>
        </MainPageWrapper>
        <Loader show={isLoading} />
    </Layout>);
}

export default MainPage;
