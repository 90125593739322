import React, {useEffect, useState} from "react";
import {
    RegisterPageWrapper,
    BackgroundImage,
    FormWrapper,
    GalilLogo,
    AlreadyHaveAccount,
    Title,
    FormInnerWrapper, ErrorMessage
} from "./styles";
import backgroundImage from '../../resources/images/login-banner.webp'
import logo from '../../resources/images/galil-logo.svg'
import {NavLink, useNavigate} from "react-router-dom";
import Input from "../../components/common/input/Input";
import Button from "../../components/common/button/Button";
import {useDispatch, useSelector} from "react-redux";
import {selectIsLoading, selectIsLoggedIn, selectRegisterSuccess} from "../../store/user/selectors";
import {userActions} from "../../store/user";
import {alertsActions} from "../../store/alerts";
import {useTranslation} from "react-i18next";

const RegisterPage: React.FC = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const isLoading = useSelector(selectIsLoading);
    const isRegistered = useSelector(selectRegisterSuccess);
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {t} = useTranslation();

    useEffect(() => {
        if(isLoggedIn) {
            navigate('/', {replace: true});
        }
    }, [isLoggedIn, navigate]);

    const onSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if(!isLoading && name.length > 0 && email.length > 0 && phone.length > 0) {
            dispatch(userActions.register({email, name, phone}));
        }
    }

    useEffect(() => {
        if(isRegistered) {
            dispatch(userActions.registerClear());
            navigate('/login', {replace: true});
            dispatch(alertsActions.enqueue({sevirity: 'success', text: t('REGISTRATION_SUCCESS_MESSAGE')}))
        }
    }, [isRegistered])

    return (<RegisterPageWrapper key="register-form-wrapper">
        <BackgroundImage src={backgroundImage} />
        <FormWrapper key="register-form">
            <GalilLogo src={logo} />
            <FormInnerWrapper key="register-form-inner-wrapper" onSubmit={onSubmit}>
                <Title>{t('FULL_NAME')}</Title>
                <Input key="register-form-name-input" textAlign="right" value={name} onChange={e => setName(e.target.value)} />
                <Title>{t('EMAIL')}</Title>
                <Input key="register-form-mail-input" value={email} onChange={e => setEmail(e.target.value)} />
                <Title>{t('PHONE_NUMBER')}</Title>
                <Input key="register-form-phone-input" value={phone} onChange={e => setPhone(e.target.value)} />
                <Button
                    key="register-form-submit-button"
                    label={t('I_WANT_TO_JOIN')}
                    type="submit"
                    disabled={isLoading || name.length === 0 || email.length === 0 || phone.length === 0}
                />
                {isRegistered === false && <ErrorMessage>{t('REGISTRATION_ERROR')}</ErrorMessage>}
            </FormInnerWrapper>
            <NavLink to="/login">
                <AlreadyHaveAccount key="register-form-login-button">
                    {t('ALREADY_REGISTERED')}
            </AlreadyHaveAccount>
            </NavLink>
        </FormWrapper>
    </RegisterPageWrapper>)
}

export default RegisterPage;
