import styled from "styled-components";
import colors from "../../../styles/colors";

export const StyledInput = styled.input<{textAlign?: 'right' | 'left'; small?: boolean; border?: boolean; error?: boolean;}>`
  min-width: ${props => props.small ? 220 : 320}px;
  height: ${props => props.small ? 24 : 36}px;
  font-size: ${props => props.small ? 0.7 : 0.9}rem;
  color: ${colors.theme.galilDark};
  background-color: white;
  border-radius: 6px;
  box-sizing: border-box;
  padding: ${props => props.small ? '2px 6px' : '4px 12px'};
  border: ${props => props.error ? `1px solid ${colors.prompt.errorText}` : (props.border ? `1px solid ${colors.theme.galilDark}` : 'none')};
  outline: 2px solid transparent;
  ${props => props.textAlign ? `text-align: ${props.textAlign};` : '' }
  &:focus {
    outline: 2px solid ${props => props.error ? colors.prompt.errorText : colors.theme.galilGreen};
    border: 1px solid transparent;
  }
`;
