import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {UserState} from "../types";
import {StorageKey} from "../storage";
import {Quota} from "../../types/Quota";
import {UserData} from "../../types/UserData";
import {Metadata} from "../../types/Metadata";

const initialState: UserState = {
    isLoggedIn: !!localStorage.getItem(StorageKey.TOKEN),
    name: localStorage.getItem(StorageKey.NAME) || undefined,
    isLoading: false,
    token: localStorage.getItem(StorageKey.TOKEN) || undefined
}

export const usersSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        getMetadata: (state: UserState) => {
            state.isLoading = true;
            state.error = undefined;
        },
        getMetadataSuccess: (state: UserState, action : PayloadAction<Metadata>) => {
            state.isLoading = false;
            state.metadata = action.payload;
        },
        getMetadataFailure: (state: UserState, action : PayloadAction<{ error: string; }>) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
        register: (state: UserState, action: PayloadAction<{ email: string; name: string; phone: string; }>) => {
            state.isLoading = true;
            state.registerSuccess = undefined;
            state.error = undefined;
        },
        login: (state: UserState, action: PayloadAction<{ username: string; password: string; }>) => {
            state.name = action.payload.username;
            state.isLoading = true;
            state.error = undefined;
            state.sessionTimeout = undefined;
        },
        logout: (state: UserState, action: PayloadAction<{ sessionTimeout?: boolean; }>) => {
            state.isLoading = false;
            state.error = undefined;
            state.isLoggedIn = false;
            state.sessionTimeout = action.payload.sessionTimeout;
            localStorage.removeItem(StorageKey.TOKEN);
            localStorage.removeItem(StorageKey.NAME);
        },
        loadData: (state: UserState) => {
            state.isLoading = true;
            state.error = undefined;
        },
        loadDataSuccess: (state: UserState, action : PayloadAction<{ quota: Quota; userData: UserData; }>) => {
            state.isLoading = false;
            state.quota = action.payload.quota;
            state.userData = action.payload.userData;
        },
        loadDataFailure: (state: UserState, action : PayloadAction<{ error: string; }>) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
        loginSuccess: (state: UserState, action : PayloadAction<{ token: string; }>) => {
            state.isLoading = false;
            state.isLoggedIn = true;
            state.token = action.payload.token;
            localStorage.setItem(StorageKey.TOKEN, action.payload.token);
        },
        loginFailure: (state: UserState, action : PayloadAction<{ error: string; }>) => {
            state.isLoading = false;
            state.isLoggedIn = false;
            state.error = action.payload.error || 'LOGIN_ERROR';
            localStorage.removeItem(StorageKey.TOKEN);
            localStorage.removeItem(StorageKey.NAME);
        },
        registerSuccess: (state: UserState, action : PayloadAction<{}>) => {
            state.isLoading = false;
            state.registerSuccess = true;
        },
        registerFailure: (state: UserState, action : PayloadAction<{ error: string; }>) => {
            state.isLoading = false;
            state.registerSuccess = false;
            state.error = action.payload.error;
        },
        registerClear: (state: UserState) => {
            state.isLoading = false;
            state.registerSuccess = undefined;
            state.error = undefined;
        },
    }
});

export const userActions = usersSlice.actions;

export default usersSlice.reducer;

