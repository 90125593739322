import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    transition: 1s linear;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    margin: 0;
    font-family: Rubik, Helvetica, Arial, sans-serif;
    overflow: hidden;
  }
`;

export default GlobalStyle;
