import React, {useMemo, useRef} from "react";
import {SignupItem, StatisticItem, Statistics} from "../../types/Statistics";
import {Card, Row, StatsWrapper, Title} from "./styles";
import {PieChart, Pie, Cell, XAxis, YAxis, Legend, PieLabel, BarChart, Tooltip, Bar, Rectangle} from "recharts";
import colors from "../../styles/colors";
import dayjs, {Dayjs} from "dayjs";
import {Loader} from "../loader";
import {useTranslation} from "react-i18next";

interface Props {
    startDate: Dayjs | null;
    endDate: Dayjs | null;
    stats: Statistics | undefined;
    isLoading?: boolean;
}

const COLORS = [colors.theme.galilGrey, colors.theme.galilOrange, colors.theme.galilBlue, colors.theme.galilGrey];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel: PieLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const StatisticsGraphs: React.FC<Props> = (props: Props) => {
    const {stats, startDate, endDate, isLoading} = props;
    const successRateBarChartCardRef = useRef<any>();
    const signupsBarChartCardRef = useRef<any>();
    const {t} = useTranslation();

    const pieChartDataSuccessRate = useMemo(() => {
        if (!stats) return [];
        return ([
            {name: 'success', value: stats.success},
            {name: 'failure', value: stats.failed}
        ]);
    }, [stats]);

    const barChartDataSuccessRate = useMemo(() => {
        if (!stats || !startDate || !endDate) return [];
        if (stats.item_type === 'hour') {
            const now = dayjs().get('hour');
            const utcOffset = dayjs().utcOffset() / 60;
            const map: { [key: number]: StatisticItem } = {};
            stats.items.forEach(item => {
                map[(Number(item.value) + utcOffset) % 24] = item;
            });
            const data = [];
            for (let i = 1; i <= 24; i++) {
                const time = (now + i) % 24;
                data.push({
                    success: map[time]?.success || 0,
                    failure: map[time]?.failed || 0,
                    total: map[time]?.total || 0,
                    value: `${time}`
                });
            }
            return data;
        } else {
            const map: { [key: string]: StatisticItem } = {};
            stats.items.forEach(item => {
                map[item.value] = item;
            });
            const diff = endDate.diff(startDate, 'days') + 1;
            const data = [];
            for (let i = 0; i < diff; i++) {
                const curDate: Dayjs = startDate.add(i, 'days');
                const fullDate = curDate.format('YYYY/MM/DD');
                data.push({
                    success: map[fullDate]?.success || 0,
                    failure: map[fullDate]?.failed || 0,
                    total: map[fullDate]?.total || 0,
                    value: curDate.format('DD/MM')
                });
            }
            return data;
        }
    }, [startDate, endDate, stats]);

    const signupsBarChartDataSuccessRate = useMemo(() => {
        if (!stats || !startDate || !endDate) return [];
        if (stats.item_type === 'hour') {
            const now = dayjs().get('hour');
            const utcOffset = dayjs().utcOffset() / 60;
            const map: { [key: number]: SignupItem } = {};
            stats.signup_items.forEach(item => {
                map[(Number(item.value) + utcOffset) % 24] = item;
            });
            const data = [];
            for (let i = 1; i <= 24; i++) {
                const time = (now + i) % 24;
                data.push({
                    count: map[time]?.count || 0,
                    value: `${time}`
                });
            }
            return data;
        } else {
            const map: { [key: string]: SignupItem } = {};
            stats.signup_items.forEach(item => {
                map[item.value] = item;
            });
            const diff = endDate.diff(startDate, 'days') + 1;
            const data = [];
            for (let i = 0; i < diff; i++) {
                const curDate: Dayjs = startDate.add(i, 'days');
                const fullDate = curDate.format('YYYY/MM/DD');
                data.push({
                    count: map[fullDate]?.count || 0,
                    value: curDate.format('DD/MM')
                });
            }
            return data;
        }
    }, [startDate, endDate, stats]);

    return (<StatsWrapper>
        <Row>
            <Card span={1} ref={successRateBarChartCardRef}>
                <Title dir="rtl">{t('TRANSACTIONS')}</Title>
                <BarChart
                    width={(successRateBarChartCardRef.current?.clientWidth || 100) - 100}
                    height={250}
                    data={barChartDataSuccessRate}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <XAxis dataKey="value"/>
                    <YAxis min={0}/>
                    <Tooltip cursor={false}/>
                    <Legend/>
                    <Bar radius={[4,4,0,0]} dataKey="success" fill={colors.theme.galilGrey} stackId="s"
                         activeBar={<Rectangle radius={[4,4,0,0]} fill={colors.theme.galilBlue} stroke={colors.theme.galilGrey}/>}/>
                    <Bar radius={[4,4,0,0]} dataKey="failure" fill={colors.theme.galilOrange} stackId="s"
                         activeBar={<Rectangle radius={[4,4,0,0]} fill={colors.theme.galilGreen} stroke={colors.theme.galilOrange}/>}/>
                </BarChart>
            </Card>
            <Card>
                <Title dir="rtl">{t('TRANSACTIONS_SUCCESS_FAILURE')}</Title>
                <PieChart width={250} height={250}>
                    <Pie
                        data={pieChartDataSuccessRate}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {pieChartDataSuccessRate.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                        ))}
                    </Pie>
                    <Legend/>
                </PieChart>
            </Card>
        </Row>
        <Row>
            <Card span={1} ref={signupsBarChartCardRef}>
                <Title dir="rtl">{t('REGISTRATION')}</Title>
                <BarChart
                    width={(signupsBarChartCardRef.current?.clientWidth || 100) - 100}
                    height={250}
                    data={signupsBarChartDataSuccessRate}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <XAxis dataKey="value"/>
                    <YAxis min={0}/>
                    <Tooltip cursor={false}/>
                    <Bar
                        radius={[4,4,0,0]}
                        dataKey="count"
                        fill={colors.theme.galilGrey}
                        activeBar={<Rectangle radius={[4,4,0,0]} fill={colors.theme.galilBlue} stroke={colors.theme.galilGrey}/>}/>
                </BarChart>
            </Card>
        </Row>
        <Loader show={isLoading} />
    </StatsWrapper>)
}

export default StatisticsGraphs;
