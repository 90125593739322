import React, {useEffect, useState} from "react";
import {
    BackgroundImage,
    ErrorMessage,
    FormWrapper,
    GalilLogo,
    LinkButton,
    LoginForm,
    LoginPageWrapper,
    Title
} from "./styles";
import backgroundImage from '../../resources/images/login-banner.webp'
import logo from '../../resources/images/galil-logo.svg'
import {Input} from "../../components/common";
import Button from "../../components/common/button/Button";
import {useDispatch, useSelector} from "react-redux";
import {selectIsLoggedIn, selectError, userActions} from "../../store/user";
import {NavLink, useNavigate} from "react-router-dom";
import {selectIsLoading, selectMetadata, selectSessionTimeout} from "../../store/user/selectors";
import {Route} from "../../routes/Route";
import {useTranslation} from "react-i18next";

const LoginPage = () => {
    const [username, setUsername] = useState(sessionStorage.getItem('email') || '');
    const [password, setPassword] = useState(sessionStorage.getItem('password') || '');

    const navigate = useNavigate();
    const { t } = useTranslation();
    const isLoading = useSelector(selectIsLoading);
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const metadata = useSelector(selectMetadata);
    const error = useSelector(selectError);
    const sessionTimeout = useSelector(selectSessionTimeout);

    const dispatch = useDispatch();

    const onUsernameChanged = (e: any) => {
        setUsername(e.target.value);
    }

    const onPasswordChanged = (e: any) => {
        setPassword(e.target.value);
    }

    const onSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if(!isLoading && username.length > 0 && password.length > 0) {
            dispatch(userActions.login({username, password}));
        }
    }

    useEffect(() => {
        if(isLoggedIn) {
            navigate(`/${Route.Upload}`, {replace: true});
        }
    }, [isLoggedIn, navigate]);

    return <LoginPageWrapper>
        <BackgroundImage src={backgroundImage} />
        <LoginForm onSubmit={onSubmit}>
            <FormWrapper>
                <Title>{t('EMAIL')}</Title>
                <Input value={username} onChange={onUsernameChanged} />
                <Title>{t('PASSWORD')}</Title>
                <Input type="password" value={password} onChange={onPasswordChanged} />
                <Button label={t('LOGIN')} type="submit" disabled={isLoading || username.length === 0 || password.length === 0} />
                {sessionTimeout && <ErrorMessage>{t('SESSION_TIMEOUT_MESSAGE')}</ErrorMessage>}
                {error && !sessionTimeout && <ErrorMessage>{t('LOGIN_FAILED_MESSAGE')}</ErrorMessage>}
                { !metadata?.is_business && <NavLink to="/register">
                    <LinkButton>{t('I_WANT_TO_REGISTER')}</LinkButton>
                </NavLink> }
            </FormWrapper>
        </LoginForm>
        <GalilLogo src={logo} />
    </LoginPageWrapper>
}

export default LoginPage;
