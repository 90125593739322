import React, { useRef, useEffect, useState } from "react";
import { FixedSizeList } from 'react-window';
import {TableWrapper, Title, Toolbar, Table, Header, Cell, Row, Actions, IconButton} from "./styles";
import {Checkbox, TableSortLabel, Tooltip} from "@mui/material";
import {LocalActivity, Password, Person, PersonOff} from "@mui/icons-material";
import Input from "../common/input/Input";
import {UserInfo} from "../../types/UserInfo";
import {UpdateQuotaDialog} from "../quota";
import {adminActions} from "../../store/admin";
import {useDispatch} from "react-redux";
import ValidationDialog from "../validation-dialog/ValidationDialog";
import {useTranslation} from "react-i18next";

interface Props {
    users: UserInfo[];
}

const isMultiselect = false

type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const UsersTable: React.FC<Props> = (props: Props) => {
    const { users } = props;
    const wrapperRef = useRef<any>();
    const [wrapperRefState, setWrapperRefState] = useState<any>();
    const [selected, setSelected] = useState<readonly string[]>([]);
    const [selectedSingle, setSelectedSingle] = useState<UserInfo | undefined>(undefined);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof UserInfo>('name');
    const [search, setSearch] = useState('');
    const [updateQuotaUserId, setUpdateQuotaUserId] = useState<string | undefined>(undefined);
    const [toggleUserData, setToggleUserData] = useState<{title: string; text: string; userId: string} | undefined>(undefined);
    const [resetPasswordData, setResetPasswordData] = useState<{title: string; text: string; userId: string} | undefined>(undefined);
    const {t} = useTranslation();
    const dispatch = useDispatch();


    const headCells: {id: keyof UserInfo; label: string; span: number; formatValue?: (user: UserInfo) => string}[] = [
        {
            id: 'name',
            label: t('NAME'),
            span: 1.5
        },
        {
            id: 'email',
            label: t('EMAIL'),
            span: 2
        },
        {
            id: 'phone',
            label: t('PHONE'),
            span: 1
        },
        {
            id: 'user_quota',
            label: t('QUOTAS'),
            formatValue: (user: UserInfo) => `${user.user_quota?.usage || 0}/${user.user_quota?.quota || 0}`,
            span: 1
        },
        {
            id: 'disabled',
            label: t('STATUS'),
            formatValue: (user: UserInfo) => t(user.disabled ? 'FROZEN' : 'ACTIVE'),
            span: 1
        },
    ];

    useEffect(() => {
        setWrapperRefState(wrapperRef.current);
    }, [wrapperRef.current]);

    const numSelected = selected.length;
    const rowCount = users.length;

    const onSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(!isMultiselect) return;
        if (event.target.checked) {
            const newSelected = users.map(user => user._id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const createSortHandler = (property: keyof UserInfo) => (event: React.MouseEvent<unknown>) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (event: any, id: string) => {
        const selectedIndex = selected.indexOf(id);
        if(!isMultiselect) {
            const single = users.findIndex(item => item._id === id);
            setSelectedSingle(selectedIndex === -1 ? users[single] : undefined);
            setSelected(selectedIndex === -1 ? [id] : []);
        } else {
            const copy = [...selected];
            if (selectedIndex === -1) {
                copy.push(id);
            } else {
                copy.splice(selectedIndex, 1);
            }
            setSelected(copy);
        }
    };

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    const rows = React.useMemo(
        // @ts-ignore
        () => search === '' ? users.slice().sort(getComparator(order, orderBy)) :
            // @ts-ignore
            users.slice().sort(getComparator(order, orderBy))
                .filter(item => item.name.includes(search) ||
                    item.email.includes(search) || item.phone.includes(search)),
        [order, orderBy, users, search],
    );

    const onUpdateQuotaClicked = () => {
        if(selected.length > 0) {
            setUpdateQuotaUserId(selected[0]);
        }
    }

    const onToggleStatusClicked = () => {
        if(selected.length > 0) {
            setToggleUserData({ userId: selected[0], title: t('STATUS_UPDATE'), text: t('STATUS_UPDATE_VERIFICATION_MESSAGE') });
        }
    }

    const onResetPasswordClicked = () => {
        if(selected.length > 0) {
            setResetPasswordData({ userId: selected[0], title: t('PASSWORD_RESET'), text: t('PASSWORD_RESET_VERIFICATION_MESSAGE') });
        }
    }

    const onUpdateQuota = (userId: string, quota: number) => {
        dispatch(adminActions.addQuota({ userId, quota }));
        setUpdateQuotaUserId(undefined);
        setSelected([]);
        setSelectedSingle(undefined);
    }

    const onToggleUser = () => {
        if(selectedSingle) {
            dispatch(adminActions.updateUserStatus({userId: selectedSingle._id, isDisabled: !selectedSingle.disabled }));
            setToggleUserData(undefined);
            setSelected([]);
            setSelectedSingle(undefined);
        }
    }

    const onResetPassword = () => {
        if(selectedSingle) {
            dispatch(adminActions.resetUserPassword({userId: selectedSingle._id }));
            setResetPasswordData(undefined);
            setSelected([]);
            setSelectedSingle(undefined);
        }
    }

    const onDialogApprove = () => {
        if(toggleUserData) {
            onToggleUser()
        } else if(resetPasswordData) {
            onResetPassword();
        }
    }

    return (<TableWrapper id="users-table">
        <Toolbar>
            <Actions>
                <Input
                    small
                    textAlign="right"
                    placeholder={t('SEARCH')}
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                />
                {selected.length > 0 && <Tooltip title={selectedSingle?.disabled ? t('ACTIVATE_USER') : t('FREEZE_USER') }>
                    <IconButton onClick={onToggleStatusClicked}>
                        { selectedSingle?.disabled ? <Person /> : <PersonOff /> }
                    </IconButton>
                </Tooltip>}
                {selected.length === 1 && <Tooltip title={t('RESET_PASSWORD')}>
                    <IconButton onClick={onResetPasswordClicked}>
                        <Password />
                    </IconButton>
                </Tooltip>}
                {selected.length === 1 && <Tooltip title={t('EDIT_QUOTA')}>
                    <IconButton onClick={onUpdateQuotaClicked}>
                        <LocalActivity />
                    </IconButton>
                </Tooltip>}
            </Actions>
            <Title>{t('USERS')}</Title>
        </Toolbar>
        <Table dir="rtl" ref={wrapperRef}>
            <Header>
                <Cell checkbox><Checkbox
                    checked={numSelected > 0}
                    onChange={onSelectAll}
                    size="small"
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                /></Cell>
                {headCells.map(cell => <Cell header span={cell.span}>
                    <TableSortLabel
                        active={orderBy === cell.id}
                        direction={orderBy === cell.id ? order : 'asc'}
                        onClick={createSortHandler(cell.id)}
                    >
                        {cell.label}
                    </TableSortLabel>
                </Cell>)}
            </Header>
            <FixedSizeList
                itemData={rows}
                height={(wrapperRefState?.clientHeight || 0) - 34}
                width="100%"
                itemSize={30}
                itemCount={rows.length}>
                {({ data, index, style }) => (<Row dir="rtl" style={style}>
                    <Cell checkbox>
                        <Checkbox
                            checked={isSelected(data[index]._id)}
                            onChange={e => handleClick(e, data[index]._id)}
                            size="small" />
                    </Cell>
                    {headCells.map(cell => <Cell span={cell.span}>
                        {/* @ts-ignore */}
                        {cell.formatValue ? cell.formatValue(data[index]) : data[index][cell.id]}
                    </Cell>)}
                </Row>)}
            </FixedSizeList>
        </Table>
        <UpdateQuotaDialog
            userId={updateQuotaUserId}
            onClose={() => setUpdateQuotaUserId(undefined)}
            onUpdateQuota={onUpdateQuota}
        />
        <ValidationDialog
            open={!!toggleUserData || !!resetPasswordData}
            onClose={() => {
                setToggleUserData(undefined);
                setResetPasswordData(undefined);
            }}
            onApprove={onDialogApprove}
            title={toggleUserData?.title || resetPasswordData?.title}
            text={toggleUserData?.text || resetPasswordData?.text}
        />
    </TableWrapper>)
}

export default UsersTable;
