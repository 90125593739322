import styled from "styled-components";
import colors from "../../styles/colors";

export const LoaderWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255,255,255,0.6);
  z-index: 99999999;
`;

export const Rect = styled.div<{index: number}>`
  width: 46px;
  height: 46px;
  border-radius: 10px;
  background-color: ${props => props.index === 0 ? colors.theme.galilGreen : (props.index === 1 ? colors.theme.galilBlue : '#EA8717')};
  position: absolute;
  left: 50%;
  top: 50%;
  animation: rect-anim-${props => props.index} 1.2s infinite linear;
  
  @keyframes rect-anim-0 {
    0% {translate: -36px 36px;}
    25% {translate: -36px -36px;}
    50% {translate: 36px -36px;}
    75% {translate: 36px 36px;}
    100%{translate: -36px 36px;}
  }
  
  @keyframes rect-anim-1 {
    0% {translate: -36px -36px;}
    25% {translate: 36px -36px;}
    50% {translate: 36px 36px;}
    75% {translate: -36px 36px;}
    100%{translate: -36px -36px;}
  }
  
  @keyframes rect-anim-2 {
    0% {translate: 36px -36px;}
    25% {translate: 36px 36px;}
    50% {translate: -36px 36px;}
    75% {translate: -36px -36px;}
    100%{translate: 36px -36px;}
  }
`;
