import React, {useMemo} from 'react';
import {BaseFeatureToggle} from "../../types/FeatureToggle";
import {FeatureToggleWrapper} from "./styles";
import {Switch} from "@mui/material";
import {Input} from "../common";
import {useTranslation} from "react-i18next";

interface Props {
    featureToggle: BaseFeatureToggle;
    onCheckChanged: (isChecked: boolean) => void;
    onDataChanged: (data: string) => void;
    onNameChanged?: (name: string) => void;
}

const FeatureToggleRow: React.FC<Props> = (props: Props) => {
    const { featureToggle, onCheckChanged, onDataChanged, onNameChanged } = props;
    const { t } = useTranslation();

    const error = useMemo(() => {
        try {
            if(featureToggle.data === '') return false;
            JSON.parse(featureToggle.data);
            return false;
        } catch (e) {
            return true;
        }
    }, [featureToggle.data]);

    return (<FeatureToggleWrapper>
        <Switch
            checked={featureToggle.enabled}
            onChange={e => onCheckChanged(!featureToggle.enabled)} />
        { onNameChanged ?
            <Input
                border
                placeholder={t('NAME')}
                value={featureToggle.name}
                onChange={e => onNameChanged(e.target.value)}
                style={{ width: '300px', minWidth: '300px', textAlign: 'right'}}
                error={featureToggle.name === ''}
            />
            :
            <span className="title">{featureToggle.name}</span> }
        <Input
            border
            placeholder="data"
            value={featureToggle.data}
            onChange={e => onDataChanged(e.target.value)}
            style={{flex: 1}}
            error={error}
        />
    </FeatureToggleWrapper>);
}

export default FeatureToggleRow;
