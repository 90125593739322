import styled from "styled-components";
import colors from "../../styles/colors";

export const AppFooter = styled.div`
  width: 100%;
  background-color: ${colors.theme.galilDark};
  color: ${colors.theme.galilGreen};
  padding: 0.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 0.7rem;
  gap: 0.6rem;
  box-sizing: border-box;
`;

export const Center = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  gap: 0.6rem;

  a {
    color: ${colors.theme.galilGreen};
    transition: color 0.3s linear;
    :hover {
      color: ${colors.theme.galilGreen80};
    }
  }
`;

export const Side = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
`;
