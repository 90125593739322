import React, {useEffect} from "react";
import {TokensPageWrapper} from "./styles";
import {useDispatch, useSelector} from "react-redux";
import {adminActions, selectTokensList} from "../../store/admin";
import {selectAdminLoading} from "../../store/admin/selectors";
import {Loader} from "../../components/loader";
import TokensTable from "../../components/tokens/TokensTable";


const TokensPage: React.FC = () => {
    const tokens = useSelector(selectTokensList);
    const loading = useSelector(selectAdminLoading);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(adminActions.getTokensList());
    }, []);

    return <TokensPageWrapper id="users-page">
        <TokensTable tokens={tokens} />
        <Loader show={loading} />
    </TokensPageWrapper>
}

export default TokensPage;
