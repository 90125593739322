import React from 'react';
const language = process.env.REACT_APP_LANGUAGE;

if (language === 'ar') {
    require('./index-ar.tsx');
} else if (language === 'he') {
    require('./index-he.tsx');
} else {
    throw new Error('No valid language specified.');
}
