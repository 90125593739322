// import {put, takeLatest , delay} from "redux-saga/effects";
// import {filesActions} from "./reducer";
// import {PayloadAction} from "@reduxjs/toolkit";

// function* func(action: PayloadAction<{ username: string; password: string; }>) {
// }

export default function* filesSaga() {
    // yield takeLatest(filesActions..type, loginUser);
}
