import React from 'react';
import {StyledButton} from "./styles";

interface Props {
    label: string;
    disabled?: boolean;
    narrow?: boolean;
    onClick?: (e: any) => void;
    type?: 'button' | 'submit' | 'reset';
}

const Button: React.FC<Props> = (props: Props) => {
    const {label, disabled, onClick, type, narrow} = props;
    return (<StyledButton narrow={narrow} disabled={disabled} type={type} onClick={onClick} >{label}</StyledButton>)
}

export default Button;
