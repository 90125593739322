import React, {useMemo} from "react";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, {Dayjs} from "dayjs";
import {FiltersWrapper, RangeButton} from "./styles";
import {useTranslation} from "react-i18next";

interface Props {
    startDate: Dayjs | null;
    endDate: Dayjs | null;
    onStartDateChanged: (date: Dayjs | null) => void;
    onEndDateChanged: (date: Dayjs | null) => void;
}

export const DashboardFilters: React.FC<Props> = (props: Props) => {
    const {startDate, endDate, onStartDateChanged, onEndDateChanged} = props;
    const { t } = useTranslation();

    const isLast24Hrs = useMemo(() => {
        return !!startDate && !!endDate && startDate?.add(1,'day').isSame(endDate, 'day')
    }, [startDate, endDate]);

    const isLast7Days = useMemo(() => {
        return !!startDate && !!endDate && endDate.isSame(dayjs(), 'day') &&
            startDate?.add(7,'day').isSame(endDate, 'day')
    }, [startDate, endDate]);

    const selectLast24Hrs = () => {
        onStartDateChanged(dayjs().subtract(1, 'day'));
        onEndDateChanged(dayjs());
    }

    const selectLast7Days = () => {
        onStartDateChanged(dayjs().subtract(7, 'day'));
        onEndDateChanged(dayjs());
    }

    return <FiltersWrapper>
        <RangeButton onClick={isLast24Hrs ? undefined : selectLast24Hrs} dir="rtl" disabled={isLast24Hrs} >{t('TWENTY_FOUR_HRS')}</RangeButton>
        <RangeButton onClick={isLast7Days ? undefined : selectLast7Days} dir="rtl" disabled={isLast7Days} >{t('SEVEN_DAYS')}</RangeButton>
        <span>{t('START_DATE')}</span>
        <DatePicker
            value={startDate}
            maxDate={dayjs().subtract(1, 'day')}
            onChange={onStartDateChanged}
            format="DD/MM/YYYY"
            slotProps={{ textField: { size: 'small' } }}
        />
        <span>{t('END_DATE')}</span>
        <DatePicker
            value={endDate}
            maxDate={dayjs()}
            onChange={onEndDateChanged}
            format="DD/MM/YYYY"
            slotProps={{ textField: { size: 'small' } }}
        />
    </FiltersWrapper>
}

export default DashboardFilters;
