import styled from "styled-components";
import colors from "../../styles/colors";

export const Key = styled.div`
  font-weight: 600;
  font-size: 1rem;
  color: ${colors.theme.galilDark};
  padding-bottom: 2px;
  padding-top: 6px;
`;

export const CardKey = styled.div`
  font-weight: 600;
  font-size: 1rem;
  color: rgb(156,163,175);
  padding-bottom: 2px;
  padding-top: 6px;
`;

export const Title = styled.div<{isMain?: boolean;}>`
  ${props => props.isMain ? '' : 'width: 100%;'}
  ${props => props.isMain ? 'flex: 1' : ''}
  align-self: flex-start;
  text-align: end;
  font-weight: 600;
  font-size: 1.4rem;
  color: rgb(55,65,81);
  padding-bottom: 10px;
  padding-top: 10px;
`;


export const PreviewTitle = styled.div`
  align-self: center;
  text-align: center;
  font-weight: 600;
  font-size: 1.4rem;
  color: rgb(55,65,81);
  padding-bottom: 10px;
  padding-top: 10px;
`;

export const PreviewSubtitle = styled.div`
  align-self: center;
  text-align: center;
  font-weight: 500;
  font-size: 1.2rem;
  color: rgb(55,65,81);
  padding-bottom: 10px;
  padding-top: 10px;
`;

export const Value = styled.div`
  font-weight: 400;
  font-size: 0.95rem;
  color: ${colors.theme.galilGreen};
`;

export const CardValue = styled.div`
  font-weight: 400;
  font-size: 0.95rem;
  color: rgb(55,65,81);
`;

export const LoadingText = styled.span`
  padding: 10px;
  font-size: 1.3rem;
`;

export const ValuesList = styled.div<{loading: boolean;}>`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.loading? 'center' : 'flex-start'};
  justify-content: ${props => props.loading? 'center' : 'flex-start'};
  box-sizing: border-box;
  flex: 1;
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
`;

export const CardRow = styled.div`
  color: rgb(55,65,81);
  width: 100%;
  border-bottom: 1px solid ${colors.theme.galilGrey30};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const CardList = styled.div<{center?: boolean;}>`
  width: calc(100% - 36px);
  display: flex;
  flex-direction: column;
  align-items: ${props => props.center ? 'center' : 'flex-start'};
  justify-content: flex-start;
  margin-left: 18px;
  margin-right: 18px;
  padding: 16px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 12px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 7px 0px;
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Row = styled.div<{header?: boolean; justifyContent?: string;}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-weight: ${props => props.header ? 600 : 400};
  justify-content: ${props => props.justifyContent || 'flex-start'};
`;

export const Cell = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 8px 4px;
`;
