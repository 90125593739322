import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    toggles: Yup.array().of(Yup.object().shape({
        name: Yup.string().required(),
        data: Yup.string().test('json', 'invalid json', value => {
            try {
                if(!value || value === '') return true;
                JSON.parse(value);
                return true;
            } catch (error) {
                return false;
            }
        })
    })),
    togglesToAdd: Yup.array().of(Yup.object().shape({
        name: Yup.string().required(),
        data: Yup.string().test('json', 'invalid json', value => {
            try {
                if(!value || value === '') return true;
                JSON.parse(value);
                return true;
            } catch (error) {
                return false;
            }
        })
    }))
});
