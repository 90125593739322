import styled from "styled-components";
import colors from "../../styles/colors";

export const FeatureTogglesPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
`;

export const Toolbar = styled.div<{selected?: boolean}>`
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.selected ? colors.theme.galilGrey : colors.theme.galilDark};
  padding: 0.4rem 1rem;
`;

export const Title = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.2rem;
  color: white;
`;

export const List = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  overflow-y: auto;
  padding: 20px;
`;

export const AddButton = styled.div`
  cursor: pointer;
  border-radius: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  margin-top: 20px;
  font-size: 0.9rem;
  &:hover {
    background-color: rgba(0,0,0,0.1);
  }
`;
