import React, {useEffect} from "react";
import { useFormik } from 'formik';
import {AddButton, FeatureTogglesPageWrapper, List, Title, Toolbar} from "./styles";
import {useDispatch, useSelector} from "react-redux";
import {adminActions, selectFeatureToggles} from "../../store/admin";
import {selectAdminLoading} from "../../store/admin/selectors";
import {Loader} from "../../components/loader";
import {FeatureToggleRow} from "../../components/feature-toggle";
import {Button} from "../../components/common/button";
import {validationSchema} from "./validation";
import {BaseFeatureToggle, FeatureToggle} from "../../types/FeatureToggle";
import {Add} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

const FeatureTogglesPage: React.FC = () => {
    const toggles = useSelector(selectFeatureToggles);
    const loading = useSelector(selectAdminLoading);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(adminActions.getFeatureToggles());
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema,
        initialValues: {
            toggles: toggles?.map(t => ({...t, data: t.data ? JSON.stringify(t.data) : ''})) || [],
            togglesToAdd: [] as BaseFeatureToggle[]
        },
        onSubmit: values => {
            const itemsToUpdate: FeatureToggle[] = [];
            values.toggles.forEach((toggle, i) => {
               if(toggle.enabled !== formik.initialValues.toggles[i].enabled ||
               toggle.data !== formik.initialValues.toggles[i].data ? JSON.stringify(formik.initialValues.toggles[i].data) : ''){
                   itemsToUpdate.push({...toggle, data: toggle.data ? JSON.parse(toggle.data) : ''})
               }
            });
            const itemsToAdd: BaseFeatureToggle[] = values.togglesToAdd.map(item => ({...item, data: item.data ? JSON.parse(item.data) : ''}));
            dispatch(adminActions.updateFeatureToggles({ update: itemsToUpdate, add: itemsToAdd }));
        }
    });

    const addRow = () => {
        const copy = [...formik.values.togglesToAdd, { name: '', data: '', enabled: true }];
        formik.setFieldValue('togglesToAdd', copy);
    }

    return (<FeatureTogglesPageWrapper id="fts-page">
        <Toolbar>
            <Button label={t('SAVE')} disabled={!formik.dirty || !formik.isValid} onClick={formik.submitForm}/>
            <Title>{t('SYSTEM_SETTINGS')}</Title>
        </Toolbar>
        <List>
        {
            formik.values.toggles.map((toggle, i) => <FeatureToggleRow
                featureToggle={toggle}
                onCheckChanged={enabled => formik.setFieldValue(`toggles[${i}].enabled`, enabled)}
                onDataChanged={data => formik.setFieldValue(`toggles[${i}].data`, data)}
            />)
        }
        {
            formik.values.togglesToAdd.map((toggle, i) => <FeatureToggleRow
                featureToggle={toggle}
                onCheckChanged={enabled => formik.setFieldValue(`togglesToAdd[${i}].enabled`, enabled)}
                onDataChanged={data => formik.setFieldValue(`togglesToAdd[${i}].data`, data)}
                onNameChanged={name => formik.setFieldValue(`togglesToAdd[${i}].name`, name)}
            />)
        }
        <AddButton onClick={addRow}>
            <span>{t('ADD_NEW_SETTING')}</span>
            <Add />
        </AddButton>
        </List>
        <Loader show={loading} />
    </FeatureTogglesPageWrapper>)
}

export default FeatureTogglesPage;
