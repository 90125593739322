import React, {PropsWithChildren} from "react";
import {useSelector} from "react-redux";
import {selectIsLoggedIn} from "../../store/user";
import {Navigate} from "react-router-dom";

const PrivateRoute = (props: PropsWithChildren) => {
    const { children } = props;
    const isLoggedIn = useSelector(selectIsLoggedIn);

    // @ts-ignore
    return (isLoggedIn ? children : <Navigate to="/login" replace />);
}

export default PrivateRoute;
