import {FilesState} from "../types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState: FilesState = {
    files: []
}

export const filesSlice = createSlice({
    name: 'files',
    initialState,
    reducers: {
        addFiles: (state: FilesState, action: PayloadAction<string[]>) => {
            state.files = [
                ...state.files,
                ...action.payload.map(file => ({ name: file, isUploaded: false, isUploading: false }))
            ];
        },
        deleteFile: (state: FilesState, action: PayloadAction<string>) => {
            const index = state.files.findIndex(item => item.name === action.payload);
            if (index > -1) {
                state.files.splice(index, 1);
            }
        },
    }
});

export const filesActions = filesSlice.actions;

export default filesSlice.reducer;
