import React, {useState} from "react";
import {QuotaProgress, QuotaProgressBar, QuotaWrapper, ToolbarGroup, ToolbarWrapper, UserBadge} from "./styles";
import {Menu, MenuItem} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {userActions} from "../../store/user";
import {selectName, selectQuota} from "../../store/user/selectors";
import {Quota} from "../../types/Quota";
import {useTranslation} from "react-i18next";

const Toolbar: React.FC = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const name = useSelector(selectName);
    const quota: Quota | undefined = useSelector(selectQuota);

    return (<ToolbarWrapper id="app-toolbar">
        <ToolbarGroup>
            <UserBadge onClick={e => setAnchorEl(e.currentTarget)}>
                {name?.toUpperCase().charAt(0) || '-'}
            </UserBadge>
            {quota && <QuotaWrapper>
                <span>{t('CURRENT_QUOTA_USAGE', { usage: quota.usage, quota: quota.quota})}</span>
                <QuotaProgressBar>
                    <QuotaProgress progress={100 * quota.usage/quota.quota}/>
                </QuotaProgressBar>
            </QuotaWrapper>}
        </ToolbarGroup>
        <ToolbarGroup>

        </ToolbarGroup>
        <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
            <MenuItem onClick={() => dispatch(userActions.logout({}))}>{t('LOGOUT')}</MenuItem>
        </Menu>
    </ToolbarWrapper>)
}

export default Toolbar;
