import { createTheme } from '@mui/material';
import colors from './colors';

const theme = createTheme({
    direction: 'rtl',
    palette: {
        primary: {
            light: colors.theme.galilGreen,
            main: colors.theme.galilDark
        },
    },
    typography: {
        fontFamily: '"Helvetica", "Arial", "sans-serif"'
    }
});

export default theme;
