import styled from "styled-components";
import colors from "../../styles/colors";

export const RegisterPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.theme.galilDark};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const BackgroundImage = styled.img`
  width: 100%;
  bottom: 0;
  position: absolute;
`;

export const GalilLogo = styled.img`
  width: 200px;
`;

export const FormWrapper = styled.div`
  background: rgba(255, 255, 255, 0.12);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.9px);
  -webkit-backdrop-filter: blur(10.9px);
  border: 1px solid rgba(255, 255, 255, 0.27);
  border-radius: 8px;
  box-sizing: border-box;
  gap: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 120px;
`;

export const FormInnerWrapper = styled.form`
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  button {
    margin-top: 8px;
    width: 100%;
  }
`;

export const AlreadyHaveAccount = styled.div`
  box-sizing: border-box;
  text-decoration: underline;
  font-size: 1rem;
  color: ${colors.theme.galilGreen};
  font-weight: normal;
  text-align: center;
  transition: color 0.3s linear;
  cursor: pointer;
  &:hover {
    color: ${colors.theme.galilGreen80};
  }
`;

export const Title = styled.div`
  font-size: 1rem;
  color: ${colors.theme.galilWhite};
  text-align: right;
  padding-top: 8px;
  align-self: flex-end;
`;

export const ErrorMessage = styled.div`
  width: 100%;
  box-sizing: border-box;
  color: red;
  font-size: 1rem;
  font-weight: normal;
  text-align: right;
`;
