import React, { PropsWithChildren } from 'react';
import {ErrorWrapper} from "./styles";

interface Props {
    t: (key: string) => string;
    onError: () => void;
}

interface State {
    errorMessage: string;
}

class ErrorBoundary extends React.Component<PropsWithChildren<Props>, State> {
    constructor(props: PropsWithChildren<Props>) {
        super(props);
        this.state = { errorMessage: '' };
    }

    static getDerivedStateFromError(error: Error) {
        return { errorMessage: error.toString() };
    }

    render() {
        const { children } = this.props;
        const { errorMessage } = this.state;

        if(errorMessage) {
            this.props.onError();
            return <ErrorWrapper>{this.props.t('FILE_LOADING_ERROR')}</ErrorWrapper>;
        }

        return children;
    }
}

export default ErrorBoundary;
