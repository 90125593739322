import React from "react";
import {Row, Title, SubTitle, ToastWrapper} from "./styles";
import icon from '../../../resources/images/ic_error.svg'

interface Props {
    title: string;
    message: string;
}

const Toast: React.FC<Props> = (props: Props) => {
    const {title, message} = props;

    return (<ToastWrapper dir="rtl">
        <Row>
            <img src={icon} alt="toast-icon" />
            <Title>{title}</Title>
        </Row>
        <SubTitle>{message}</SubTitle>
    </ToastWrapper>);
}

export default Toast;
