import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AdminState} from "../types";
import {UserInfo} from "../../types/UserInfo";
import {Statistics} from "../../types/Statistics";
import {BaseFeatureToggle, FeatureToggle} from "../../types/FeatureToggle";
import {Token} from "../../types/Token";
import {Customer} from "../../types/Customer";

const initialState: AdminState = {
    isLoading: false,
    users: [],
    stats: undefined,
    featureToggles: undefined,
    customerInfo: undefined,
    tokens: []
}

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        getUsersList: (state: AdminState) => {
            state.isLoading = true;
            state.error = undefined;
        },
        getUsersListSuccess: (state: AdminState, action : PayloadAction<{ users: UserInfo[] }>) => {
            state.isLoading = false;
            state.users = action.payload.users;
        },
        getUsersListFailure: (state: AdminState, action : PayloadAction<{ error: string; }>) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
        getTokensList: (state: AdminState) => {
            state.isLoading = true;
            state.error = undefined;
        },
        getTokensListSuccess: (state: AdminState, action : PayloadAction<{ tokens: Token[] }>) => {
            state.isLoading = false;
            state.tokens = action.payload.tokens;
        },
        getTokensListFailure: (state: AdminState, action : PayloadAction<{ error: string; }>) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
        addQuota: (state: AdminState, action : PayloadAction<{ userId: string; quota: number; }>) => {
            state.isLoading = true;
            state.error = undefined;
        },
        addQuotaSuccess: (state: AdminState, action : PayloadAction<{ users: UserInfo[] }>) => {
            state.isLoading = false;
            state.users = action.payload.users;
        },
        addQuotaFailure: (state: AdminState, action : PayloadAction<{ error: string; }>) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
        updateUserStatus: (state: AdminState, action : PayloadAction<{ userId: string; isDisabled: boolean; }>) => {
            state.isLoading = true;
            state.error = undefined;
        },
        updateUserStatusSuccess: (state: AdminState, action : PayloadAction<{ users: UserInfo[] }>) => {
            state.isLoading = false;
            state.users = action.payload.users;
        },
        updateUserStatusFailure: (state: AdminState, action : PayloadAction<{ error: string; }>) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
        resetUserPassword: (state: AdminState, action : PayloadAction<{ userId: string; }>) => {
            state.isLoading = true;
            state.error = undefined;
        },
        resetUserPasswordSuccess: (state: AdminState) => {
            state.isLoading = false;
        },
        resetUserPasswordFailure: (state: AdminState, action : PayloadAction<{ error: string; }>) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
        getStatistics: (state: AdminState, action : PayloadAction<{ startDate: number; endDate: number }>) => {
            state.isLoading = true;
            state.error = undefined;
        },
        getStatisticsSuccess: (state: AdminState, action : PayloadAction<{ stats: Statistics }>) => {
            state.isLoading = false;
            state.stats = action.payload.stats;
        },
        getStatisticsFailure: (state: AdminState, action : PayloadAction<{ error: string; }>) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
        getFeatureToggles: (state: AdminState) => {
            state.isLoading = true;
            state.error = undefined;
        },
        getFeatureTogglesSuccess: (state: AdminState, action : PayloadAction<FeatureToggle[]>) => {
            state.isLoading = false;
            state.featureToggles = action.payload;
        },
        getFeatureTogglesFailure: (state: AdminState, action : PayloadAction<{ error: string; }>) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
        getCustomerInfo: (state: AdminState) => {
            state.isLoading = true;
            state.error = undefined;
        },
        getCustomerInfoSuccess: (state: AdminState, action : PayloadAction<Customer>) => {
            state.isLoading = false;
            state.customerInfo = action.payload;
        },
        getCustomerInfoFailure: (state: AdminState, action : PayloadAction<{ error: string; }>) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
        updateCustomerInfo: (state: AdminState, action : PayloadAction<Partial<Customer>>) => {
            state.isLoading = true;
            state.error = undefined;
        },
        updateCustomerInfoSuccess: (state: AdminState) => {
            state.isLoading = false;
        },
        updateCustomerInfoFailure: (state: AdminState, action : PayloadAction<{ error: string; }>) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
        updateFeatureToggles: (state: AdminState, action : PayloadAction<{ add: BaseFeatureToggle[]; update: FeatureToggle[] }>) => {
            state.isLoading = true;
            state.error = undefined;
        },
        updateFeatureTogglesSuccess: (state: AdminState) => {
            state.isLoading = false;
        },
        updateFeatureTogglesFailure: (state: AdminState, action : PayloadAction<{ error: string; }>) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
        deleteToken: (state: AdminState, action : PayloadAction<{ id: string }>) => {
            state.isLoading = true;
            state.error = undefined;
        },
        deleteTokenSuccess: (state: AdminState) => {
            state.isLoading = false;
        },
        deleteTokenFailure: (state: AdminState, action : PayloadAction<{ error: string; }>) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
        addToken: (state: AdminState, action : PayloadAction<{ name: string; folder: string; }>) => {
            state.isLoading = true;
            state.error = undefined;
        },
        addTokenSuccess: (state: AdminState) => {
            state.isLoading = false;
        },
        addTokenFailure: (state: AdminState, action : PayloadAction<{ error: string; }>) => {
            state.isLoading = false;
            state.error = action.payload.error;
        }
    }
});

export const adminActions = adminSlice.actions;

export default adminSlice.reducer;
