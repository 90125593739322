import styled from 'styled-components';
import colors from '../../styles/colors';

export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 0.8rem;
  font-weight: 600;
  color: white;
  .MuiInputBase-input {
    color: white;
    font-size: 0.8rem;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: white !important;
  }
  svg {
    fill: white;
  }
`;

export const RangeButton = styled.div<{disabled?: boolean;}>`
  font-size: 1rem;
  padding: 0.4rem;
  background-color: ${props => props.disabled ? colors.theme.galilBlue10 : colors.theme.galilGreen};
  min-width: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  height: 24px;
  ${props => props.disabled ? '' : 'cursor: pointer;'}
  user-select: none;
  &:hover {
    background-color: ${props => props.disabled ? colors.theme.galilGreen10 : colors.theme.galilGreen80};
  }
`;
