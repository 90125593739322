import React, {useMemo} from "react";
import {Logo, Menu, MenuItem, MenuTitle, SidebarSection, SidebarWrapper} from "./styles";
import logo from '../../resources/images/galil-logo.svg';
import {Route} from "../../routes/Route";
import {CloudUpload, ManageAccounts, QueryStats, SettingsSuggest, SupervisedUserCircle, Business} from "@mui/icons-material";
import {useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectMetadata, selectUserData} from "../../store/user/selectors";
import {Role} from "../../types/Role";
import {useTranslation} from "react-i18next";

const menuItems = [
    // {id: Route.Home, label: 'HOME_PAGE', Icon: Home},
    {id: Route.Upload, label: 'UPLOAD_PAGE', Icon: CloudUpload}
]

const adminMenuItems = [
    {id: Route.Tokens, label: 'TOKENS_PAGE', Icon: SupervisedUserCircle}
]

const superAdminMenuItems = [
    {id: Route.Dashboard, label: 'SYSTEM_STATUS_PAGE', Icon: QueryStats},
    {id: Route.FeatureToggles, label: 'FEATURE_TOGGLES_PAGE', Icon: SettingsSuggest},
    {id: Route.Users, label: 'USERS_PAGE', Icon: ManageAccounts},
]

const superAdminBusinessMenuItems = [
    {id: Route.BusinessSettings, label: 'BUSINESS_SETTINGS_PAGE', Icon: Business},
    ...superAdminMenuItems
];

const Sidebar: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const userData = useSelector(selectUserData);
    const metadata = useSelector(selectMetadata);
    const {t} = useTranslation();

    const isAdmin = useMemo(() => -1 !== userData?.roles?.findIndex(role => role === Role.Admin),
        [userData]);

    const isSuperAdmin = useMemo(() => -1 !== userData?.roles?.findIndex(role => role === Role.SuperUser),
        [userData]);

    const navigateTo = (route: Route) => () => {
        navigate(`/${route}`, {replace: true});
    }

    return <SidebarWrapper>
        <SidebarSection>
            <Logo src={logo}/>
            <Menu>
                <MenuTitle>{t('MENU')}</MenuTitle>
                {
                    menuItems.map(item => <MenuItem
                        selected={location.pathname === `/${item.id}` || (location.pathname === '/' && item.id === Route.Upload)}
                        onClick={navigateTo(item.id)}
                    >
                        {t(item.label)}
                        <item.Icon/>
                    </MenuItem>)
                }
                {
                    isAdmin && metadata?.is_business && adminMenuItems.map(item => <MenuItem
                        selected={location.pathname === `/${item.id}` || (location.pathname === '/' && item.id === Route.Upload)}
                        onClick={navigateTo(item.id)}
                    >
                        {t(item.label)}
                        <item.Icon/>
                    </MenuItem>)
                }
                {
                    isSuperAdmin && <>
                        <MenuTitle>{t('MANAGEMENT')}</MenuTitle>
                        {
                            (metadata?.is_business ? superAdminBusinessMenuItems : superAdminMenuItems).map(item => <MenuItem
                                selected={location.pathname === `/${item.id}`}
                                onClick={navigateTo(item.id)}
                            >
                                {t(item.label)}
                                <item.Icon/>
                            </MenuItem>)
                        }
                    </>
                }
            </Menu>
        </SidebarSection>
        <SidebarSection>
            <MenuTitle>{ metadata?.version ? t('VERSION_STRING', {version: metadata.version}) : '' }</MenuTitle>
        </SidebarSection>
    </SidebarWrapper>

}

export default Sidebar;
