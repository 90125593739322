import createSagaMiddleware from "@redux-saga/core";
import { configureStore } from "@reduxjs/toolkit";
import { all, fork } from "redux-saga/effects";
import {userReducer, userSaga} from './user';
import {filesReducer, filesSaga} from './files';
import {alertsReducer} from './alerts';
import {adminReducer, adminSaga} from './admin';

const sagaMiddleware = createSagaMiddleware();

const rootSaga = function* () {
    yield all([
        fork(userSaga),
        fork(filesSaga),
        fork(adminSaga),
    ]);
};

const store = configureStore({
    reducer: {
        user: userReducer,
        files: filesReducer,
        alerts: alertsReducer,
        admin: adminReducer
    },
    middleware: g => g().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export default store;
