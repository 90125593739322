import styled from "styled-components";
import colors from "../../styles/colors";

export const ToolbarWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-height: 30px;
  padding: 4px 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.theme.galilWhite};
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
  z-index: 100;
`;

export const Logo = styled.img`
    height: 32px;
`;

export const UserBadge = styled.div`
  width: 28px;
  height: 28px;
  background-color: ${colors.theme.galilBlue};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: white;
  font-weight: 700;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
`;

export const ToolbarGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

export const QuotaWrapper = styled.div`
  background-color: transparent;
  border-radius: 6px;
  padding: 2px 4px;
  gap: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 0.9rem;
  color: ${colors.theme.galilGreen};
`;

export const QuotaProgressBar = styled.div`
  width: 200px;
  height: 10px;
  box-sizing: border-box;
  padding: 1px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: ${colors.theme.galilGrey30};
`;
export const QuotaProgress = styled.div<{progress: number;}>`
  width: ${props => props.progress }%;
  height: 8px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: ${colors.theme.galilGreen};
`;
